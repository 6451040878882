import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Utils
import { baseErrorCond } from '@toolkit/util/app';
import { cond, verifyProperty } from '@src/shared/src/util/general';
import { t } from '@toolkit/util/i18n';
import { formatOnlyDate } from '@src/shared/src/util/date';
// Constants
// Services
// Models, Stores & Interfaces
import { IRootState } from '@src/store';
import { usersActions } from '@src/shared/src/actions';
// Components
import { Link, MenuListDevider, Modal } from '@toolkit/ui';
import SearchBarGuestForm from './SearchBarGuestForm';
import { HailIcon } from '@toolkit/ui/icons';

const SearchBarGuestCreator: React.FC = () => {
  const dispatch = useDispatch();
  const [formModalVisible, setFormModalVisible] = React.useState(false);
  const appSettingsFlightLoyaltyCards = useSelector(
    (state: IRootState) => state.appSettings.config.flightLoyaltyCards,
  );
  const appSettingsTrainLoyaltyCards = useSelector(
    (state: IRootState) => state.appSettings.config.trainLoyaltyCards,
  );
  const userTitles = useSelector((state: IRootState) => state.appSettings.config.userTitles);

  const handleSubmit = (values: any) => {
    dispatch(
      usersActions.createGuestUserAsync.request({
        onSuccess: () => setFormModalVisible(false),
        onError: (err) => {
          setFormModalVisible(false);
          return cond(baseErrorCond)(err);
        },
        firstName: verifyProperty('', values, ['createGuest', 'firstName']),
        lastName: verifyProperty('', values, ['createGuest', 'lastName']),
        title: verifyProperty('', values, ['createGuest', 'title']),
        birthdateOn: verifyProperty(undefined, values, ['createGuest', 'birthdateOn'])
          ? formatOnlyDate(values.createGuest.birthdateOn.toISOString())
          : undefined,
        gender: verifyProperty('', values, ['createGuest', 'gender', 'value']),
        appliesTo: verifyProperty(undefined, values, ['addCard', 'appliesTo']),
        cardIdent: verifyProperty(undefined, values, ['addCard', 'cardIdent']),
        cardNumber: verifyProperty(undefined, values, ['addCard', 'cardNumber']),
      }),
    );
  };

  return (
    <React.Fragment>
      <MenuListDevider />
      <Link onClick={() => setFormModalVisible(true)} icon={<HailIcon />}>
        {t('search.bar.passengers.link.addGuestTravellers')}
      </Link>
      <Modal
        isOpen={formModalVisible}
        onClose={() => setFormModalVisible(false)}
        title={t('search.bar.passengers.link.addGuestTravellers')}>
        <SearchBarGuestForm
          appSettingsFlightLoyaltyCards={appSettingsFlightLoyaltyCards}
          appSettingsTrainLoyaltyCards={appSettingsTrainLoyaltyCards}
          userTitles={userTitles}
          onSubmit={handleSubmit}
        />
      </Modal>
    </React.Fragment>
  );
};

export default SearchBarGuestCreator;
