import * as React from 'react';
import { PropsWithChildren, FC } from 'react';
import './styles/Collapse.scss';

export const Collapse: FC<PropsWithChildren<{ isExpanded: boolean }>> = ({
  isExpanded,
  children,
}) => {
  const ref = React.useRef<HTMLDivElement>(null);
  const [contentHeight, setContentHeight] = React.useState(0);

  React.useEffect(() => {
    if (ref.current) setContentHeight(ref.current.clientHeight);
  }, [children]);

  return (
    <div
      className={`collapse ${isExpanded ? 'is--open' : ''}`}
      style={{ height: isExpanded ? contentHeight : 0 }}>
      <div ref={ref} className="collapse-inner">
        {children}
      </div>
    </div>
  );
};
