import * as adminUserEpics from './adminUserEpics';
import * as appSettingsEpics from './appSettingsEpics';
import * as basketEpics from './basketEpics';
import * as checkoutEpics from './checkoutEpics';
import * as filterEpics from './filterEpics';
import * as generalEpics from './generalEpics';
import * as hotelsEpics from './hotelEpics';
import * as searchEpics from './searchEpics';
import * as tripsEpics from './tripsEpics';
import * as usersEpics from './usersEpics';
import * as authEpics from './authEpics';

export {
  adminUserEpics,
  appSettingsEpics,
  basketEpics,
  checkoutEpics,
  filterEpics,
  generalEpics,
  hotelsEpics,
  searchEpics,
  tripsEpics,
  usersEpics,
  authEpics,
};