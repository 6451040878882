import * as React from 'react';

// Utils
import { isNilOrEmpty, isNil } from '@src/shared/src/util/general';
import { getUserFullname } from '@src/shared/src/util/users';
import { useSelector } from 'react-redux';
// Constants
// Actions
import { selectors } from '@src/shared/src';
// Models
import { SettingsModel, PassengerModel } from '@src/shared/src/models';
// Interfaces
import { IRootState } from '@src/store';
// Components
import { SidebarRoute } from '@toolkit/ui';
import { AccountCirlceIcon } from '@toolkit/ui/icons';
// Styles
type Props = {
  loyaltyCards:SettingsModel[];
};

const SideBarPassengersInfo: React.FC<Props> = (props:Props) => {
  const passengers = useSelector((state: IRootState) => selectors.search.searchPassengers(state.search))

  const filteredPassengers = passengers.map((passenger:PassengerModel, index:number) => {
    if (!isNil(passenger)) {
      return (
        <SidebarRoute icon={<AccountCirlceIcon />} key={index}>
          {getUserFullname(passenger)}
          &nbsp;
          {!isNilOrEmpty(passenger.membershipCards) &&
            `(${(passenger.membershipCards).join(' | ')})`}
        </SidebarRoute>
      );
    }
    return null;
  });

  return (
    <span>{filteredPassengers}</span>
  );
};

export default SideBarPassengersInfo;
