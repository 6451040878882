import * as React from 'react';

// Utils
import { isNil } from '@src/shared/src/util/general';
// Constants
// Actions, Models & Interfaces
// Components
import SliderLabel from './SliderLabel';
import { SidebarSection } from '@toolkit/ui';
// Styles
import 'rc-slider/assets/index.css';
import '../styles/SliderFilter.scss';

type Props = Partial<{
  title:string;
  isOpen:boolean;
  currentMin:State['currentMin'],
  min:number;
  currentMax:State['currentMax'],
  max:number;
  onChange:(value:number[]) => void;
  edgeFormatValue?: (value:number ) => React.ReactNode;
  midFormatValue?: (value:number ) => React.ReactNode;
  render:RenderProps
}>;
const initialState = {
  isOpen: true,
  currentMin: 0,
  currentMax: 0,
};
type State = Readonly<typeof initialState>;
type RenderProps = (cmp:BaseFilterComponentProps) => JSX.Element;
type BaseFilterComponentProps = {
  currentMin:State['currentMin'],
  min:number;
  currentMax:State['currentMax'],
  max:number;
  onChange:BaseFilter['onChange']
};

export default class BaseFilter extends React.Component<Props, State> {
  readonly state:State = initialState;

  componentWillMount() {
    this.setState({
      isOpen: isNil(this.props.isOpen) ? true : this.props.isOpen,
      currentMin: this.props.currentMin || this.props.min,
      currentMax: this.props.currentMax || this.props.max
    });
  }

  componentWillReceiveProps(nextProps:Props) {
    if (!isNil(nextProps.isOpen) && nextProps.isOpen !== this.props.isOpen) {
      this.setState({ isOpen: nextProps.isOpen });
    }

    if (
      (nextProps.currentMax !== this.props.currentMax)
      ||
      (nextProps.currentMin !== this.props.currentMin)
    ) {
      this.setState({
        currentMax: nextProps.currentMax,
        currentMin: nextProps.currentMin,
      });
    }
  }

  private onChange = (currentValue:number[]) => {
    this.setState({
      currentMin: currentValue[0],
      currentMax: isNil(currentValue[1]) ? this.state.currentMax : currentValue[1]
    });
    this.props.onChange(currentValue);
  }

  render() {
    const { title, min, max, currentMax, currentMin, edgeFormatValue,
      midFormatValue, render } = this.props;
  
    const renderProps = {
      currentMax: this.state.currentMax,
      currentMin: this.state.currentMin,
      min,
      max,
      onChange: this.onChange
    };
    return (
      <SidebarSection title={title} isOpen={this.state.isOpen}>
        {render(renderProps)}
        <SliderLabel
          minValue={min}
          currentMinValue={currentMin}
          maxValue={max}
          currentMaxValue={currentMax}
          edgeFormatValue={edgeFormatValue}
          midFormatValue={midFormatValue}
        />
      </SidebarSection>
    );
  }
}
