import * as React from 'react';
import { useTranslation } from 'react-i18next';
import WarningBanner from './WarningBanner';

type Props = {
  isSearchExpired: boolean;
};

export const SearchExpiredBanner: React.FC<Props> = ({ isSearchExpired }) => {
  const { t } = useTranslation();

  if (isSearchExpired) {
    return (
      <WarningBanner
        icon="lp-icon-warning"
        label={t('tripsConn.notification.text.searchExpired')}
      />
    );
  }
};
