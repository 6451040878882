import { createAsyncAction } from 'typesafe-actions';

// Utils
// Constants
// Actions
// Models
// Interfaces
import { ActionCallback } from '../interfaces';

export const mobileAuthAsync = createAsyncAction(
    'auth/MOBILE_AUTH_REQUEST',
    'auth/MOBILE_AUTH_SUCCESS',
    'auth/MOBILE_AUTH_FAILURE'
)<ActionCallback, void, void>();