import * as React from 'react';

// Utils
import { formatDate } from '@src/shared/src/util/date';
// Constants
import { DATE_FORMAT_TYPES } from '@src/shared/src/const/app';
// Actions
// Models
// Interfaces
// Components
import { SidebarRoute } from '@toolkit/ui';
import { AccountCirlceIcon, CalendarIcon, HotelIcon, PencilIcon } from '@toolkit/ui/icons';
import { useTranslation } from 'react-i18next';
// Styles

type Props = {
  title: string;
  hotelName: string;
  checkinDate: Date;
  checkoutDate: Date;
  travellersCount: number;
  roomCount: number;
  onEdit: () => void;
};

const CheckoutSideBarHotelInfo: React.FC<Props> = (props: Props) => {
  const { t } = useTranslation();
  return (
    <div className="sidebar-inner">
      <div className="sidebar-title">
        {props.title}
        {props.onEdit && (
          <button onClick={props.onEdit}>
            <PencilIcon />
          </button>
        )}
      </div>
      <div className="sidebar-trip-details">
        <SidebarRoute icon={<HotelIcon />}>{props.hotelName}</SidebarRoute>
        <SidebarRoute icon={<CalendarIcon />}>
          {`
            ${formatDate(props.checkinDate, DATE_FORMAT_TYPES.DAY_SHORT_DATE)}
            -
            ${formatDate(props.checkoutDate, DATE_FORMAT_TYPES.DAY_SHORT_DATE)}
          `}
        </SidebarRoute>
        <SidebarRoute icon={<AccountCirlceIcon />}>{props.travellersCount} {t('checkoutSideBarHotelInfo.travelers')}</SidebarRoute>
        <SidebarRoute icon={<HotelIcon />}>{props.roomCount} {t('checkoutSideBarHotelInfo.rooms')}</SidebarRoute>
      </div>
    </div>
  );
};

export default CheckoutSideBarHotelInfo;
