import { Epic } from 'redux-observable';
import { isActionOf } from 'typesafe-actions';
import {
    catchError,
    filter,
    mergeMap,
    switchMap,
} from 'rxjs/operators';

// Utils
import {
    apiRequest,
    apiRequestError,
} from '../util/api';
// Constants
import {
    API_URL,
} from '../const/api';
// Actions
import {
    generalActions,
    authActions,
} from '../actions';
// Models
// Interfaces
import { RootAction } from '../interfaces';

export const mobileAuthEpic: Epic<RootAction, RootAction> = (action$) =>
    action$.pipe(
        filter(isActionOf(authActions.mobileAuthAsync.request)),
        switchMap((action) =>
            apiRequest(API_URL.MOBILE_AUTH).pipe(
                mergeMap((res: any) => {
                    return [
                        generalActions.applyExtActionAsync.request({
                            callback: action.payload.onSuccess,
                            param: res.response,
                        }),
                    ];
                }),
                catchError((err) => {
                    return apiRequestError(action.payload.onError, 'mobileAuthError', err);
                }),
            ),
        ),
    );
