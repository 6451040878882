import * as React from 'react';

// Utils
import { isNotNil } from '@src/shared/src/util/general';
// Constants
// Actions, Models & Interfaces
// Components
// Styles
import '../styles/SliderLabel.scss';

interface IProps {
  minValue:number;
  currentMinValue:number;
  maxValue:number;
  currentMaxValue:number;
  edgeFormatValue?: (value:number ) => React.ReactNode;
  midFormatValue?: (value:number ) => React.ReactNode;
}
const SliderLabel:React.FC<IProps> = (props) => {
  return (
    <div className="tcp-slider-label">
      <div className="tcp-slider-label-from">
        {isNotNil(props.edgeFormatValue) ? props.edgeFormatValue(props.minValue) : props.minValue}
      </div>
      <div className="tcp-slider-label-value">
        <span>
          {isNotNil(props.midFormatValue) ?
            props.midFormatValue(props.currentMinValue) : props.currentMinValue}
        </span>
        <span>&nbsp;-&nbsp;</span>
        <span>
          {isNotNil(props.midFormatValue) ?
            props.midFormatValue(props.currentMaxValue) : props.currentMaxValue}
        </span>
      </div>
      <div className="tcp-slider-label-to">
        {isNotNil(props.edgeFormatValue) ?
          props.edgeFormatValue(props.maxValue) : props.maxValue}
      </div>
    </div>
  );
};
export default SliderLabel;
