import { JsonObject, JsonProperty } from 'json2typescript';

@JsonObject('OrganizationModel')
export default class OrganizationModel {
  @JsonProperty('id', Number) public id: number = undefined;
  @JsonProperty('name', String) public name: string = undefined;
  @JsonProperty('omniauth_name', String) public omniauthName: string = undefined;
  @JsonProperty('rental_enabled', Boolean) public rentalEnabled: boolean = undefined;
  @JsonProperty('multi_cost_centers_enabled', Boolean) public multiCostCentersEnabled: boolean = true;
  @JsonProperty('omniauth_strategy', String) public omniauthStrategy: string = undefined;
  @JsonProperty('logo_path', String) public logoPath: string = undefined;
  @JsonProperty('prevent_vacation_rentals', Boolean) public preventVacationRentals: boolean = true;
  @JsonProperty('prepay_hotel_rates_filter_enabled', Boolean) public prepayHotelRatesFilterEnabled: boolean = undefined;
  @JsonProperty('cost_units_enabled', Boolean) public costUnitsEnabled: boolean = undefined;
  @JsonProperty('multi_cost_units_enabled', Boolean) public multiCostUnitsEnabled: boolean = undefined;
  @JsonProperty('show_intercom_onboarding', Boolean) public showIntercomOnboarding: boolean = undefined;

  isDemo = () => {
    return /demo/i.test(this.name);
  }
}
