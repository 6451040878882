import * as React from 'react';
import classNames from 'classnames';
import { ChevronIcon } from './icons';
import { Collapse } from './Collapse';

type Props = React.PropsWithChildren & {
  title: string;
  tooltip?: string;
  isOpen?: boolean;
};

const SidebarSection: React.FC<Props> = (props) => {
  const { title, isOpen, tooltip, children } = props;
  const [isExpanded, setIsExpanded] = React.useState(isOpen);

  return (
    <div
      className={classNames('sidebar-section', {
        'is--open': isExpanded,
      })}>
      <button className="sidebar-section-title" onClick={() => setIsExpanded(!isExpanded)}>
        {title}
        {tooltip && <i data-rh={tooltip} className="icon-info_outline" />}

        <ChevronIcon />
      </button>
      <Collapse isExpanded={isExpanded}>
        <div className="sidebar-section-inner">{children}</div>
      </Collapse>
    </div>
  );
};

export default SidebarSection;
