import * as React from 'react';

// Utils
import { useTranslation } from 'react-i18next';
// Constants
// Models
import { OptionModel } from '@src/shared/src/models';
// Interfaces
// Components
// Styles
import '../styles/SortBar.scss';

type Props = {
  options: OptionModel[];
  value: string;
  onChange: (val: OptionModel) => void;
};

const SortBar: React.FC<Props> = ({ options, value, onChange }) => {
  const renderButton = (option: OptionModel) => (
    <button
      key={option.value}
      onClick={() => onChange(option)}
      className={`lp-sortbar-button ${value === option.value ? 'is--active' : ''}`}>
      {option.label}
    </button>
  );

  const { t } = useTranslation();

  return (
    <div className="lp-sortbar">
      <label className="lp-sortbar-label">{t('SortBar.label.sort')}</label>
      {options.map((option: OptionModel) => renderButton(option))}
    </div>
  );
};

export default SortBar;
