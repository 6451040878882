import * as React from 'react';

// Utils
import { requireAsset } from '@toolkit/util/app';

// Constants
// Actions, Models & Interfaces
// Utils
// Components
// Styles
import './styles/LogoPrimary.scss';


const LogoPrimary: React.FC = () => {
  return (
    <div className="logo-primary">
      <img src={requireAsset('logos/primary.svg')} />
    </div>
  );
};

export default LogoPrimary;
