import * as React from 'react';

// Utils
import { navigate, baseErrorCond } from '@toolkit/util/app';
import { cond, isEmpty, isNil, verifyProperty, hasEmptyValues } from '@src/shared/src/util/general';
import { useDispatch } from 'react-redux';
import useBreakpoints from '@toolkit/util/useBreakpoints';
import { useSelector } from 'react-redux';
import classNames from 'classnames';

// Constants
import { DIRECTION, SEARCH_TYPE } from '@src/shared/src/const/app';
import { API_URL } from '@src/shared/src/const/api';
import { ROUTES } from '@toolkit/const/app';
// Actions, Models & Interfaces
import { selectors } from '@src/shared/src';
import { adminUserSelectors, searchSelectors } from '@src/shared/src/selectors';
import { searchActions, settingsActions } from '@src/shared/src/actions';
import { PassengerModel } from '@src/shared/src/models';
import { IRootState } from '@src/store';
import {
  setUiSearchArrSuggestOverlay,
  setUiSearchDepSuggestOverlay,
  setUiSearchDepAtOverlay,
  setUiSearchArrAtOverlay,
  setUiSearchPassengersOverlay,
  setUiSearchConciergeOverlay,
} from '@pod/ui/actions';

// Components
import {
  SearchBarDateTime,
  SearchBarFilter,
  SearchBarPassengers,
  SearchBarConcierge,
  SearchBarRoute,
  SearchSubmitButton,
  SearchOptions,
} from '@pod/search/components';
import LogoPrimary from '@toolkit/ui/LogoPrimary';
import { BENotificationBar, RebookingBanner } from '@toolkit/ui';
// Styles
import '../styles/SearchBar.scss';

import { isSearchingForOnlyHotelSelector, isSearchingForOnlyOutboundSelector } from '@pod/settings/selectors';
import { uiSearchArrAtOverlaySelector, uiSearchArrSuggestOverlaySelector, uiSearchConciergeOverlaySelector, uiSearchDepAtOverlaySelector, uiSearchDepSuggestOverlaySelector, uiSearchPassengersOverlaySelector } from '@pod/ui/selectors';

const SearchBarConn: React.FC = () => {
  const [isSearchButtonLoading, SetIsSearchButtonLoading] = React.useState(false);
  const dispatch = useDispatch();
  const {isLg} = useBreakpoints();

  const searchType = useSelector((state: IRootState) => selectors.search.searchType(state));
  const search = useSelector((state: IRootState) => selectors.search.search(state.search));
  const depSuggestions = useSelector((state: IRootState) => selectors.search.searchDepSuggestions(state.search));
  const arrSuggestions = useSelector((state: IRootState) => selectors.search.searchArrSuggestions(state.search));
  const isSearchingForOnlyHotel = useSelector((state: IRootState) => isSearchingForOnlyHotelSelector(state));
  const isSearchingForOnlyOutbound = useSelector((state: IRootState) => isSearchingForOnlyOutboundSelector(state));
  const uiSearchDepSuggestOverlay = useSelector((state: IRootState) => uiSearchDepSuggestOverlaySelector(state));
  const uiSearchArrSuggestOverlay = useSelector((state: IRootState) => uiSearchArrSuggestOverlaySelector(state));
  const uiSearchDepAtOverlay = useSelector((state: IRootState) => uiSearchDepAtOverlaySelector(state));
  const uiSearchArrAtOverlay = useSelector((state: IRootState) => uiSearchArrAtOverlaySelector(state));
  const uiSearchPassengersOverlay = useSelector((state: IRootState) => uiSearchPassengersOverlaySelector(state));
  const uiSearchConciergeOverlay = useSelector((state: IRootState) => uiSearchConciergeOverlaySelector(state));
  const searchTimeWindow = useSelector((state: IRootState) => state.filters.tripTimeWindowUserFilter);
  const profile = useSelector((state: IRootState) => state.adminUser.profile);
  const isCurrentUserAdmin = useSelector((state: IRootState) => adminUserSelectors.isCurrentUserAdmin(state.adminUser));
  const isCurrentUserTravelAssistant = useSelector((state: IRootState) => adminUserSelectors.isCurrentUserTravelAssistant(state.adminUser));
  const isCurrentUserManager = useSelector((state: IRootState) => adminUserSelectors.isCurrentUserManager(state.adminUser));
  const searchPassengers = useSelector((state: IRootState) => searchSelectors.searchPassengers(state.search));
  const organization = useSelector((state: IRootState) => state.organization.org);
  const isRebookingSearch = useSelector((state: IRootState) => searchSelectors.isRebookingSearch(state));


  React.useEffect(() => {
    if (
      !isNil(profile) &&
      !isCurrentUserTravelAssistant &&
      isEmpty(searchPassengers)
    ) {
      const firstPassenger: PassengerModel = new PassengerModel();
      firstPassenger.firstName = profile.firstName;
      firstPassenger.lastName = profile.lastName;
      firstPassenger.userId = profile.id;
      firstPassenger.role = profile.role;
      dispatch(searchActions.setSearchPassengers([firstPassenger]));
    }
  }, []);

  const handleOverlays = (overlayStr: string, modeOpen: boolean = true) => {
    // Close all overlays
    dispatch(setUiSearchArrAtOverlay(false));
    dispatch(setUiSearchDepAtOverlay(false));
    dispatch(setUiSearchArrSuggestOverlay(false));
    dispatch(setUiSearchDepSuggestOverlay(false));
    dispatch(setUiSearchPassengersOverlay(false));
    dispatch(setUiSearchConciergeOverlay(false));
    if (modeOpen) {
      switch (overlayStr) {
        case 'arrAt':
          dispatch(setUiSearchArrAtOverlay(true));
          break;
        case 'depAt':
          dispatch(setUiSearchDepAtOverlay(true));
          break;
        case 'arrSuggest':
          dispatch(setUiSearchArrSuggestOverlay(true));
          break;
        case 'depSuggest':
          dispatch(setUiSearchDepSuggestOverlay(true));
          break;
        case 'passengersSelector':
          dispatch(setUiSearchPassengersOverlay(true));
          break;
        case 'concierge':
          dispatch(setUiSearchConciergeOverlay(true));
          break;
      }
    }
  };

  const onFetchLocationDetail = (isDep: boolean, location: any) => {
    dispatch(searchActions.fetchLocationDetailAsync.request({ isDep, location }));
  };

  const onSetSearchDepAt = (val) => {
    dispatch(searchActions.setSearchDepAt(val));
  };

  const onSetSearchArrAt = (val) => {
    dispatch(searchActions.setSearchArrAt(val));
  };

  const handleStartSearch = () => {
    SetIsSearchButtonLoading(true);

    dispatch(
      searchActions.startSearchAsync.request({
        onSuccess: (searchRes) => {
          if (search.searchType === SEARCH_TYPE.RENTAL) {
            window.location.href = `${API_URL.DEFAULT}/api/rental/searches/${searchRes.id}`;
          } else if (isSearchingForOnlyHotel) {
            navigate(`${ROUTES.HOTELS}${searchRes.id}`);
          } else {
            navigate(`${ROUTES.TRIPS.OUTWARD}${searchRes.id}`);
          }
        },
        onError: (err) => {
          SetIsSearchButtonLoading(false);
          return cond(baseErrorCond)(err);
        },
        search,
      }),
    );
  };
  return (
    <>
      <BENotificationBar currentPage="Home" />

      <aside className={classNames("search-bar",{'has--brand-logo': organization?.logoPath} )}>
        <div className="search-bar-inner">
          {!isLg && <LogoPrimary />}

          <RebookingBanner />
          
          {
            // for rebooking searches we should not allow users to change the search type and filters
            !isRebookingSearch && (
              <>
                <SearchOptions />
                <SearchBarFilter
                  searchType={searchType}
                  onSetSearchHotelRoomsType={(val) =>
                    dispatch(searchActions.setSearchHotelRoomsType(val))
                  }
                  currentRoomType={search.roomType}
                />
              </>
            )
          }

          <SearchBarRoute
            onSetUiSearchSuggestDepOverlay={handleOverlays}
            onSetUiSearchSuggestArrOverlay={handleOverlays}
            onSetIsSearchingForOnlyHotel={(val) =>
              dispatch(settingsActions.setIsSearchingForOnlyHotel(val))
            }
            onSetIsSearchingForOnlyOutbound={(val) =>
              dispatch(settingsActions.setIsSearchingForOnlyOutbound(val))
            }
            isSearchingForOnlyHotel={isSearchingForOnlyHotel}
            depSuggestions={depSuggestions}
            arrSuggestions={arrSuggestions}
            uiSearchSuggestDepOverlay={uiSearchDepSuggestOverlay}
            uiSearchSuggestArrOverlay={uiSearchArrSuggestOverlay}
            onFetchLocationDetail={onFetchLocationDetail}
            homeLocations={
              hasEmptyValues(profile?.preference?.locationHome)
                ? null
                : profile.preference.locationHome
            }
            workLocations={
              hasEmptyValues(profile?.preference?.locationWork)
                ? null
                : profile.preference.locationWork
            }
            favoriteLocations={verifyProperty([], profile, [
              'preference',
              'locationFavorite',
            ])}
            profile={profile}
            transportInputDisabled={isRebookingSearch}
          />

          <div
            className={`search-bar-spacer ${
              searchType === SEARCH_TYPE.ALL || searchType === SEARCH_TYPE.OUTBOUND
                ? 'is--small'
                : ''
            }`}
          />

          <SearchBarDateTime
            onSetSearchDepAt={onSetSearchDepAt}
            onSetSearchArrAt={onSetSearchArrAt}
            onSetUiSearchDepAtOverlay={handleOverlays}
            onSetUiSearchArrAtOverlay={handleOverlays}
            onSetIsSearchingForOnlyOutbound={(val) =>
              dispatch(settingsActions.setIsSearchingForOnlyOutbound(val))
            }
            isSearchingForOnlyOutbound={isSearchingForOnlyOutbound}
            isSearchingForOnlyHotel={isSearchingForOnlyHotel}
            uiSearchDepAtOverlay={uiSearchDepAtOverlay}
            uiSearchArrAtOverlay={uiSearchArrAtOverlay}
            searchTimeWindow={searchTimeWindow}
            disableToggleButton={isRebookingSearch}
            disableDepDatePicker={
              search?.rebooking?.originalFareDirection === DIRECTION.INBOUND
            }
            disableArrDatePicker={
              search?.rebooking?.originalFareDirection === DIRECTION.OUTWARD
            }
          />
          <SearchBarPassengers
            onSetUiSearchPassengersOverlay={handleOverlays}
            uiSearchPassengersOverlay={uiSearchPassengersOverlay}
            searchPassengers={searchPassengers}
            isCurrentUserAdmin={isCurrentUserAdmin}
            isCurrentUserManager={isCurrentUserManager}
            isCurrentUserTravelAssistant={isCurrentUserTravelAssistant}
            organizationId={profile.organizationId}
            disabled={isRebookingSearch}
          />
          <div className="search-bar-button">
            <SearchSubmitButton
              onStartSearch={() => handleStartSearch()}
              isLoading={isSearchButtonLoading}
            />
          </div>
        </div>
        <div className="search-bar-inner">
          <SearchBarConcierge
            uiSearchConciergeOverlay={uiSearchConciergeOverlay}
            onSetUiSearchConciergeOverlay={handleOverlays}
            profile={profile}
          />
        </div>
      </aside>
    </>
  );
};

export default SearchBarConn;
