import * as React from 'react';

// Utils
import { getSupplierNameByCode } from '@src/shared/src/util/app';
// Constants
// Actions
// Models
import { OptionModel } from '@src/shared/src/models';
// Interfaces
// Components

import { CheckboxFilter } from './CheckboxFilter';
import { SidebarSection } from '@toolkit/ui';
// Styles

type Props = {
  title: string;
  suppliers: OptionModel[];
  isOpen?: boolean;
  onChange: (vehicles: OptionModel[]) => void;
};

export default class SupplierFilter extends React.PureComponent<Props> {
 
  public render() {
    const { title, suppliers, isOpen } = this.props;
  
    return (
      <SidebarSection title={title} isOpen={isOpen}>
        {suppliers.map((supplier: OptionModel) => (
          <CheckboxFilter
            id={`su-id-${supplier.label}`}
            key={`su-key-${supplier.label}`}
            label={`${getSupplierNameByCode(supplier.label)}`}
            isChecked={Boolean(supplier.value)}
            onChange={val => {
              const nSuppliers: any = suppliers.map((sup: OptionModel) =>
                sup.label === supplier.label
                  ? {
                      label: sup.label,
                      value: val,
                    }
                  : sup,
              );
              this.props.onChange(nSuppliers);
            }}
            onChangeOnly={() => {
              const nSuppliers: any = suppliers.map((sup: OptionModel) =>
                sup.label === supplier.label ? { label: sup.label, value: true } : { label: sup.label, value: false },
              );
              this.props.onChange(nSuppliers);
            }}
          />
        ))}
       </SidebarSection>
    );
  }
}
