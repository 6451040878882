import * as React from 'react';

// Utils
import { formatDayDifference, head, isNotNil, map, pipe, prop } from '@src/shared/src/util/general';
import { getLegTransportSegments, getSelectedTariff } from '@src/shared/src/util/trips';
import { getVehicleIcon } from '@toolkit/util/app';
import { formatDate } from '@src/shared/src/util/date';
import { t } from '@toolkit/util/i18n';
// Constants
import { DATE_FORMAT_TYPES, ENVIRONMENT, STATUS, VEHICLE_TYPES } from '@src/shared/src/const/app';
// Actions, Models & Interfaces
import { LegModel, TariffModel, TransportSegmentModel, TravelBookingFareExtraModel } from '@src/shared/src/models';
// Components
import LegWaypoint from './LegWaypoint';
import TransportSegment from './TransportSegment';
import TransportSegmentTicketImages from './TransportSegmentTicketImages';
import { LabelButton, Spinner } from '@toolkit/ui';
import TariffDetails from './TariffDetails';
// Styles
import '../styles/Leg.scss';

type Props = {
  env: ENVIRONMENT;
  tripId: number;
  leg: LegModel;
  expanded: boolean;
  searchDate: Date;
  isLastLeg?: boolean;
  waitingTime?: number;
  getTariffStatus?: (tariffId: number) => string;
  getTariffLuggage?: (tariffId: number) => TravelBookingFareExtraModel;
  onChangeShuttleTime: (legOptionId: number, timing: string) => void;
  isBasketEditable?: boolean;
};

type State = {
  isTimetableCollapsed: boolean;
  legFirstTSDepAt: Date;
  isLoadingShuttle: boolean;
};

export default class Leg extends React.Component<Props, State> {
  readonly state: State = {
    isTimetableCollapsed:
      this.props.env === ENVIRONMENT.JOURNEY
        ? false
        : this.props.leg.legOption.changeable && this.props.leg.legOption.transportSegments.length > 1,
    legFirstTSDepAt: pipe(
      getLegTransportSegments,
      head,
      prop('depAt'),
    )(this.props.leg),
    isLoadingShuttle: false,
  };

  static getDerivedStateFromProps(nextProps: Props, prevState: State) {
    const nextLegFirstTSDepAt = pipe(
      getLegTransportSegments,
      head,
      prop('depAt'),
    )(nextProps.leg);
    if (nextLegFirstTSDepAt !== prevState.legFirstTSDepAt) {
      return {
        legFirstTSDepAt: nextLegFirstTSDepAt,
        isLoadingShuttle: false,
      };
    }
    return null;
  }

  private onChangeShuttleTime = (legOptionId: number, timing: string) => {
    this.setState({ isLoadingShuttle: true }); // Will be set to false in next WS update
    this.props.onChangeShuttleTime(legOptionId, timing);
  };

  private getChangeShuttleTimeMarkup = (env: ENVIRONMENT, leg: LegModel) => {
    if (leg.legOption.changeable && leg.legOption.estimated) {
      return (
        <div className="tcp-leg-button-group">
          <p>{t('leg.estimatedTransitResults')}</p>
        </div>
      );
    }
    if (env === ENVIRONMENT.SEARCH && leg.legOption.changeable && this.props.isBasketEditable) {
      return (
        <div className="tcp-leg-button-group">
          <LabelButton
            isLoading={this.state.isLoadingShuttle}
            onClick={() => this.onChangeShuttleTime(leg.legOption.id, 'earlier')}
            icon="icon-arrow_upward"
            className="tcp-leg-button">
            {t('global.earlier')}
          </LabelButton>
          <LabelButton
            isLoading={this.state.isLoadingShuttle}
            onClick={() => this.onChangeShuttleTime(leg.legOption.id, 'later')}
            icon="icon-arrow_downward"
            className="tcp-leg-button">
            {t('global.later')}
          </LabelButton>
        </div>
      );
    }
  };

  private getBookedStatus = (tariffs: TariffModel[]) => {
    const selectedTariff: TariffModel = getSelectedTariff(tariffs);
    if (isNotNil(selectedTariff)) {
      // selected tariff exists
      switch (this.props.getTariffStatus(selectedTariff.id)) {
        case STATUS.CONFIRM_FAILED:
          return (
            <div className="tcp-leg-status">
              <i className="icon-refresh" />
              {t('LegComponent.status.label.pending')}
            </div>
          );
        default:
          return null;
      }
    }
  };

  private getExpandButtonMarkup = () => {
    if (this.props.leg.legOption.changeable && this.props.leg.legOption.transportSegments.length > 1) {
      return (
        <button onClick={() => this.toggleTimetable()} className="tcp-leg-expand">
          {this.props.leg.legOption.transportSegments.length} {t('TransportSement.button.transfers')}
          {this.state.isTimetableCollapsed ? <i className="icon-keyboard_arrow_down" /> : <i className="icon-keyboard_arrow_up" />}
        </button>
      );
    }
  };

  private toggleTimetable = () => {
    this.setState({ isTimetableCollapsed: !this.state.isTimetableCollapsed });
  };

  public render() {
    const { leg, searchDate, env } = this.props;
    const legFirstTS: TransportSegmentModel = head(leg.legOption.transportSegments);
    const legFirstTSVehicle = head(legFirstTS.vehicles);
    const legVehicleIcon =
      legFirstTSVehicle === VEHICLE_TYPES.WALKING
        ? getVehicleIcon(VEHICLE_TYPES.PUBLIC_TRANSIT)
        : getVehicleIcon(legFirstTSVehicle);
    return (
      <>
        {env === ENVIRONMENT.CONFIRMATION && this.getBookedStatus(leg.legOption.tariffs)}
        <div
          className={`tcp-leg
          ${this.props.leg.legOption.changeable ? 'tcp-leg-transit' : ''}
          ${this.props.isLastLeg ? 'tcp-leg-last' : ''}
          ${this.state.isTimetableCollapsed ? 'is--collapsed' : ''}`}>
          <div className="tcp-leg-time">
            {formatDate(legFirstTS.depAt, DATE_FORMAT_TYPES.SHORT_TIME)}
            <sup>{formatDayDifference(legFirstTS.depAt, searchDate)}</sup>
          </div>
          <div key={leg.id} className={`tcp-leg-item`}>
            <LegWaypoint icon={legVehicleIcon} vertical={true} isChangeable={leg.legOption.changeable} />
            <div className="tcp-leg-timetable">
              {this.props.isLastLeg && this.getChangeShuttleTimeMarkup(env, leg)}

              <div className="tcp-leg-location">
                <span className="tcp-leg-location-label">{legFirstTS.depName}</span>
              </div>
              <div className="tcp-leg-segments">
                <div>
                  {map(
                    (transportSegment: TransportSegmentModel) => (
                      <TransportSegment key={transportSegment.id} env={env} transportSegment={transportSegment} />
                    ),
                    leg.legOption.transportSegments,
                  )}
                </div>
                {this.getExpandButtonMarkup()}
              </div>
              {!this.props.isLastLeg && this.props.waitingTime ? (
                <div className="tcp-leg-waiting">
                  <div className="tcp-leg-waiting-inner">
                    <span>{t('LegComponent.label.transferWaitingTime')}:</span>
                    <strong>&nbsp;{this.props.waitingTime} min</strong>
                  </div>
                </div>
              ) : null}
              {!this.props.isLastLeg && this.getChangeShuttleTimeMarkup(env, leg)}
            </div>
            <div className="tcp-leg-tickets">
              <TransportSegmentTicketImages
                isChangeable={leg.legOption.changeable}
                transportSegments={leg.legOption.transportSegments}
              />
              {this.props.expanded ? (
                <TariffDetails
                  tripId={this.props.tripId}
                  env={env}
                  changeable={leg.legOption.changeable}
                  vehicle={head(legFirstTS.vehicles)}
                  tariffs={leg.legOption.tariffs}
                />
              ) : (
                <Spinner />
              )}
            </div>
          </div>
        </div>
      </>
    );
  }
}
