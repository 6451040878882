export const COSTCENTERS_RESULTS_PER_PAGE = 10;

export const enum TRANSPORT_ICONS {
  BUS = 'icon-bus',
  PLANE = 'icon-plane',
  TRAIN = 'icon-train',
  PUBLIC_TRANSPORT = 'icon-bus',
  TAXI = 'icon-local_taxi',
  NONE = 'icon-remove_circle_outline',
  WALKING = 'icon-walk',
}

export const ROUTES = {
  HOME:         '/',
  SEARCH:       '/search/',
  AUTH: {
    DEFAULT:    '/auth/',
    LOGIN:      '/auth/login/',
  },
  BOOKING: {
    DEFAULT:    '/booking/',
    RESERVE:    '/booking/reserve/',
    PREPARE:    '/booking/prepare/',
    PAYMENT:    '/booking/payment/',
    CONFIRM:    '/booking/confirm/',
  },
  HOTELS:       '/hotels/',
  TRIPS: {
    DEFAULT:    '/trips/',
    INBOUND:    '/trips/inbound/',
    OUTWARD:    '/trips/outward/',
  },
  SUPPORT_REQUESTS: '/support_requests/',
};

export const LAST_ERROR_STATUSES = {
  PREPARE_FULFILLMENT_TYPE_NOT_ALLOWED: 'prepare_fulfillment_type_not_allowed',
};

export const enum LOCATION_TYPES {
  ESTABLISHMENT = 'establishment',
  LOCALITY = 'locality',
  SUBLOCALITY = 'sub_locality',
  TRANSIT_STATION = 'transit_station',
  LODGING = 'lodging',
}

export const enum LOCATION_TYPES_ICONS {
  ESTABLISHMENT = 'icon-domain',
  LOCALITY = 'icon-locality_default',
  TRANSIT_STATION = 'icon-station_default',
  LODGING = 'icon-location_city',
}

export const enum CHECKOUT_STEPS {
  RESERVE = 'reserve',
  PREPARE = 'prepare',
  PAYMENT = 'payment',
  CONFIRM = 'confirm',
}

export const enum SEARCH_BAR_RENTAL_LINK_TYPE {
  MULTI_STOP = 'gabelflug_grund',
  SHUTTEL = 'shuttle_limousinen_flughafentransfer_de_',
  SPECIALHOTELS = 'spezielle_hotels_unterkünfte',
  AIRPORTPARKING = 'parken_fh',
  RENTAL = 'mietwagen_contact_form',
  GROUPTRAVEL = 'gruppenreise',
  OTHER = '__dc.sonstiges__',
  FERRY = 'fähre_grundfürkontaktaufnahme',
}

export enum INITIAL_SEARCH_LIST {
  NUM = 100,
  STEP = 50,
}

export enum INTERCOM {
  APP_ID = 'ijvp5wxz'
}
