import * as React from 'react';
import { connect } from 'react-redux';

// Utils
import { navigate } from '@toolkit/util/app';
import { isNilOrEmpty, isNil, prop } from '@src/shared/src/util/general';
import { t } from '@toolkit/util/i18n';
// Constants
import { ENVIRONMENT, STATUS } from '@src/shared/src/const/app';
import { ROUTES } from '@toolkit/const/app';
// Actions, Models & Interfaces
import { TripModel, HotelModel, SearchModel, SettingsModel, OrganizationModel, PassengerModel } from '@src/shared/src/models';
import { selectors } from '@src/shared/src';
import { appSettingsSelectors } from '@src/shared/src/selectors';
import { ConnectedRedux, IRootState } from '@src/store';
// Components
import {
  CheckoutSideBarHotelInfo,
  CheckoutSideBarTripInfo,
  CheckoutSidebarConfirmBody,
  CheckoutTimer
} from '@pod/checkout/components';
import { SearchSideBarInfo } from '@pod/search/components';
// Styles

type Props = ConnectedRedux<IRootState> & {
  bookingId: string;
  bookingTrips:TripModel[];
  bookingHotels:HotelModel[];
  search:SearchModel;
  passengers:PassengerModel[];
  env:ENVIRONMENT;
  preparedAt:Date;
  outwardTrip:TripModel;
  inboundTrip:TripModel;
  basketStatus:string;
  loyaltyCards:SettingsModel[];
  isSearchingForOnlyHotel:boolean;
  isBasketEditable:boolean;
  onTimerExpired: () => void;
  exceedsAllowance?:boolean;
  organization: OrganizationModel;
  bookingHasAirbnb: boolean;
};
class CheckoutSideBarConn extends React.Component<Props> {
  private getCheckoutTimerMarkup = (preparedAt:Date) => {
    if (this.props.basketStatus === STATUS.PREPARE_FINISHED) {
      return (
        <CheckoutTimer
          onTimerExpired={this.props.onTimerExpired}
          bookingCreatedAt={preparedAt}/>
      );
    }
  }

  private getBookingTripsMarkup = () => {
    const { outwardTrip, inboundTrip, search } = this.props;

    return (
      <>
        {!isNil(outwardTrip) &&
          <CheckoutSideBarTripInfo
            key={`trip-${outwardTrip.id}`}
            title={t('CheckoutSideBarTripInfo.title.outboundTrip')}
            trip={outwardTrip}
            onEdit={
              this.props.isBasketEditable ?
                () => navigate(`${ROUTES.TRIPS.OUTWARD}${search.id}`) : null}/>
        }
        {!isNil(inboundTrip) &&
          <CheckoutSideBarTripInfo
            key={`trip-${inboundTrip.id}`}
            title={t('CheckoutSideBarTripInfo.title.inboundTrip')}
            trip={inboundTrip}
            onEdit={
              this.props.isBasketEditable ?
                () => navigate(`${ROUTES.TRIPS.INBOUND}${search.id}`) : null}/>
        }
      </>
    );
  }

  private getBookingHotelMarkup = () => {
    const { bookingHotels, search, passengers } = this.props;
    if (!isNilOrEmpty(bookingHotels)) {
      return bookingHotels.map((hotel:HotelModel, idx:number) =>
        <CheckoutSideBarHotelInfo
          key={`hotel-${idx}`}
          title={t('CheckoutSideBarTripInfo.title.hotel')}
          hotelName={hotel.name}
          checkinDate={search.depAt}
          checkoutDate={search.arrAt}
          travellersCount={passengers.length}
          roomCount={search.hotelRoomCount}
          onEdit={
            this.props.isBasketEditable ?
              () => navigate(`${ROUTES.HOTELS}${search.id}`) : null}/>
      );
    }
  }

  private getCheckoutSideBarBody = () => {
    if (this.props.env === ENVIRONMENT.CHECKOUT || this.props.env === ENVIRONMENT.PAYMENT) {
      const { search, loyaltyCards, isSearchingForOnlyHotel } = this.props;
      return (
        <span>
          <SearchSideBarInfo
            hideResetButton={this.props.exceedsAllowance}
            from={prop('depName', search)}
            to={prop('arrName', search)}
            depAt={prop('depAt', search)}
            arrAt={prop('arrAt', search)}
            loyaltyCards={loyaltyCards}
            isSearchingForOnlyHotel={isSearchingForOnlyHotel}/>
          {this.getBookingTripsMarkup()}
          {this.getBookingHotelMarkup()}
        </span>
      );
    }

    return <CheckoutSidebarConfirmBody
      basketStatus={this.props.basketStatus as STATUS}
      bookingId={this.props.bookingId}/>
  }

  public render() {
    return (
      <aside className="sidebar sidebar-trip is-open">
        <div className="sidebar-wrapper">
          {this.props.env !== ENVIRONMENT.CONFIRMATION &&
            this.getCheckoutTimerMarkup(this.props.preparedAt)
          }
          {this.getCheckoutSideBarBody()}
        </div>
      </aside>
    );
  }
}

const mapStateToProps = (state:IRootState) => ({
  bookingTrips: state.checkout.trips,
  bookingHotels: state.checkout.hotels,
  search: selectors.search.search(state.search),
  passengers: selectors.search.searchPassengers(state.search),
  preparedAt: selectors.checkout.preparedAt(state.checkout),
  outwardTrip: selectors.checkout.outwardTrip(state.checkout),
  inboundTrip: selectors.checkout.inboundTrip(state.checkout),
  basketStatus: selectors.checkout.basketStatus(state),
  loyaltyCards: appSettingsSelectors.loyaltyCards(state),
  isSearchingForOnlyHotel: state.settings.isSearchingForOnlyHotel,
  organization: state.organization.org,
  bookingHasAirbnb: selectors.checkout.isAnyBookingItemAirbnb(state),
});
export default connect(mapStateToProps)(CheckoutSideBarConn);
