import * as React from 'react';
import Select from 'react-select';

// Utils
import { t } from '@toolkit/util/i18n';
import { getShuttleIcon } from '@toolkit/util/app';
// Constants
import { TRANSIT_TYPES } from '@src/shared/src/const/app';
// Actions, Models & Interfaces
import { OptionModel } from '@src/shared/src/models';
// Components
import { SidebarSection } from '@toolkit/ui';

// Styles
import '../styles/TripShuttleTypeSelector.scss';

type Props = {
  onSelectDep: any;
  onSelectArr: any;
  arrivalShuttle: string;
  departureShuttle: string;
  isOpen: boolean;
};

export default class TripShuttleTypeSelector extends React.PureComponent<Props> {
  shuttlesOptions = (): OptionModel[] => [
    { value: TRANSIT_TYPES.PUBLIC_TRANSIT, label: t('TripShuttleTypeSelector.select.option.publicTransit') },
    { value: TRANSIT_TYPES.TAXI, label: t('TripShuttleTypeSelector.select.option.taxi') },
    { value: TRANSIT_TYPES.NONE, label: t('TripShuttleTypeSelector.select.option.none') },
  ];

  getOptionMarkup = (opt: OptionModel) => (
    <div>
      <i className={`tcp-sidebar-route-icon ${getShuttleIcon(opt.value)}`} />
      {opt.label}
    </div>
  );

  render() {
  
    return (
        <SidebarSection title={t('tripShuttleTypeSelector.title')} isOpen={this.props.isOpen}>
          <div className="tcp-trip-shuttle-type-selector">
            <span className="tcp-trip-shuttle-type-selector-transit-title">
              {t('tripShuttleTypeSelector.select.label.atDeparture')}:
            </span>
            <Select
              clearable={false}
              backspaceRemoves={false}
              deleteRemoves={false}
              className="tcp-trip-shuttle-type-selector-select"
              valueRenderer={this.getOptionMarkup}
              optionRenderer={this.getOptionMarkup}
              options={this.shuttlesOptions()}
              searchable={false}
              value={this.props.departureShuttle}
              onChange={nOpt => {
                if (this.props.departureShuttle !== nOpt.value) {
                  this.props.onSelectDep(nOpt.value);
                }
              }}
            />
          </div>
          <div className="tcp-trip-shuttle-type-selector">
            <span className="tcp-trip-shuttle-type-selector-transit-title">
              {t('tripShuttleTypeSelector.select.label.atArrival')}:
            </span>
            <Select
              clearable={false}
              backspaceRemoves={false}
              deleteRemoves={false}
              className="tcp-trip-shuttle-type-selector-select"
              valueRenderer={this.getOptionMarkup}
              optionRenderer={this.getOptionMarkup}
              options={this.shuttlesOptions()}
              searchable={false}
              value={this.props.arrivalShuttle}
              onChange={nOpt => {
                if (this.props.arrivalShuttle !== nOpt.value) {
                  this.props.onSelectArr(nOpt.value);
                }
              }}
            />
          </div>
      </SidebarSection>
    );
  }
}
