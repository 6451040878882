import * as React from 'react';
import { useTranslation } from 'react-i18next';

// Constants
// Actions, Models & Interfaces
// Components
// Styles
import './styles/DemoBanner.scss';

export const DemoBanner: React.FC = () => {
  const { t } = useTranslation();
  return (
    <div className="demo-banner">
      <div className="demo-banner-inner">{t('DemoBanner.text')}</div>
    </div>
  );
};

export default DemoBanner;
