import * as React from 'react';

// Utils
import { formatDayDifference } from '@src/shared/src/util/general';
import { generateTripRoutes } from '@toolkit/util/app';
import { formatDate } from '@src/shared/src/util/date';
// Constants
import { DATE_FORMAT_TYPES } from '@src/shared/src/const/app';
// Actions, Models & Interfaces
import { LegModel } from '@src/shared/src/models';
// Components
import LegWaypoint from './LegWaypoint';
// Styles
import '../styles/TripRoutes.scss';
import { PinIcon } from '@toolkit/ui/icons';

interface IProps {
  tripDepAt: Date;
  tripArrAt: Date;
  tripLegs: LegModel[];
  tripDuration: number;
  searchDate?: Date;
}
export default class TripRoutes extends React.PureComponent<IProps, {}> {
  private getTripTimeMarkup = (tripTime: Date, searchDate: Date) => {
    return (
      <span>
        {formatDate(tripTime, DATE_FORMAT_TYPES.SHORT_TIME)}{' '}
        <sup>{formatDayDifference(tripTime, searchDate)}</sup>
      </span>
    );
  };

  private getRoutesMarkup = (tripLegs: LegModel[], tripDuration: number) => {
    const markupSegments = generateTripRoutes(tripLegs, tripDuration);
    return markupSegments.map((mSegment) => (
      <div
        key={mSegment.key}
        className={`tcp-trip-routes-item ${mSegment.class}`}
        style={mSegment.style}>
        <LegWaypoint icon={mSegment.icon} />
      </div>
    ));
  };

  public render() {
    const { tripArrAt, tripDepAt, tripLegs, tripDuration, searchDate } = this.props;

    return (
      <div className="tcp-trip-routes">
        <div className="tcp-trip-routes-time">{this.getTripTimeMarkup(tripDepAt, searchDate)}</div>
        <div className="tcp-trip-routes-legs">
          <PinIcon className="tcp-trip-routes-icon-dep" />
          {this.getRoutesMarkup(tripLegs, tripDuration)}
          <PinIcon className="tcp-trip-routes-icon-arr" />
        </div>
        <div className="tcp-trip-routes-time">{this.getTripTimeMarkup(tripArrAt, searchDate)}</div>
      </div>
    );
  }
}
