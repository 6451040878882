import { IRootState } from '@src/store';

export const uiSearchDepSuggestOverlaySelector = (state:IRootState) => state.ui.uiSearchDepSuggestOverlay;
export const uiSearchArrSuggestOverlaySelector = (state:IRootState) => state.ui.uiSearchArrSuggestOverlay;
export const uiSearchDepAtOverlaySelector = (state:IRootState) => state.ui.uiSearchDepAtOverlay;
export const uiSearchArrAtOverlaySelector = (state:IRootState) => state.ui.uiSearchArrAtOverlay;
export const uiSearchPassengersOverlaySelector = (state:IRootState) => state.ui.uiSearchPassengersOverlay;
export const uiSearchConciergeOverlaySelector = (state:IRootState) => state.ui.uiSearchConciergeOverlay;
export const uiMobileFilterOverlay = (state:IRootState) => state.ui.uiMobileFilterOverlay;
export const uiMobileNoScrolling = (state:IRootState) => state.ui.uiMobileNoScrolling;
