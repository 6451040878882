import { getType } from 'typesafe-actions';

// Utils
import {
  convertArrToObjById,
  merge,
} from '../util/general';
// Constants
// Actions
import { TripsActions, tripsActions } from '../actions';
// Models
import { TripModel, TravelBookingFareModel } from '../models';
// Interfaces
import { ObjectArray } from '../interfaces';

export interface ITripsState {
  inbound: ObjectArray<TripModel>;
  outward: ObjectArray<TripModel>;
  outwardFilteredIds: string[],
  inboundFilteredIds: string[],
  selectedOutboundId: number;
  selectedInboundId: number;
  lastWSUpdated: number;
  allowanceMatchingTripIds: number[];
  allowanceOriginalBookingTripFares: TravelBookingFareModel[];
}

const initialState: ITripsState = {
  inbound: {},
  outward: {},
  outwardFilteredIds: [],
  inboundFilteredIds: [],
  selectedOutboundId: -1,
  selectedInboundId: -1,
  lastWSUpdated: -1,
  allowanceMatchingTripIds: [],
  allowanceOriginalBookingTripFares: [],
};

export default (state: ITripsState = initialState, action: TripsActions) => {
  switch (action.type) {
    case getType(tripsActions.addTrips):
      return {
        ...state,
        [action.payload.direction]: merge(state[action.payload.direction], convertArrToObjById(action.payload.trips))
      }
    case getType(tripsActions.setFilteredTripIds):
      return {
        ...state,
        [`${action.payload.direction}FilteredIds`]: action.payload.tripIds
      }
    case getType(tripsActions.setSelectedInboundId):
      return { ...state, selectedInboundId: action.payload };
    case getType(tripsActions.setSelectedOutboundId):
      return { ...state, selectedOutboundId: action.payload };
    case getType(tripsActions.setLastWSUpdated):
      return { ...state, lastWSUpdated: action.payload };
    case getType(tripsActions.setExpanded):
      return {
        ...state,
        [action.payload.direction]: {
          ...state[action.payload.direction],
          [action.payload.tripId]: {
            ...state[action.payload.direction][action.payload.tripId],
            expanded: action.payload.expanded
          }
        }
      }
    case getType(tripsActions.setAllowanceMatchingTripIds):
      return { ...state, allowanceMatchingTripIds: action.payload }
    case getType(tripsActions.setAllowanceOriginalFares):
      return { ...state, allowanceOriginalBookingTripFares: action.payload }
    case getType(tripsActions.clearTrips):
      return initialState;
    default:
      return state;
  }
};
