import * as React from 'react';
import './styles/SidebarRoute.scss';

interface IProps {
  icon?: JSX.Element;
  children: any;
}

const SidebarRoute: React.FC<IProps> = (props) => {
  return (
    <div className="sidebar-route">
      {props.icon}
      <div className="sidebar-route-title">
        {props.children}
      </div>
    </div>
  );
};

export default SidebarRoute;
