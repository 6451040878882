import * as React from 'react';
import { useEffect, useState, useRef } from 'react';
import cn from 'classnames';

import { ClearIcon } from './icons';

import './styles/TextField.scss';

type Props = {
  label?: string;
  name?: string;
  value: string;
  onChange?: (inputValue: string) => void;
  onFocus?: () => void;
  isVisible?: boolean;
  placeholder?: string;
  autoComplete?: boolean;
  onBlur?: () => void;
  errorMsg?: string;
  hasError?: boolean;
  icon?: JSX.Element;
  trailingIcon?: JSX.Element;
  showClear?: boolean;
  disabled?: boolean;
  forceFocus?: boolean;
  size?: "small" | "large";
};

const ON_BLUR_TIMEOUT = 400;

const TextField: React.FC<Props> = ({
  label,
  name,
  value,
  onFocus,
  onChange,
  onBlur,
  placeholder,
  autoComplete,
  errorMsg,
  hasError,
  icon,
  trailingIcon,
  showClear,
  disabled,
  forceFocus,
  size = "large"
}: Props) => {
  const [hasFocus, setHasFocus] = useState(false);
  const [touched, setTouched] = useState(false);
  const [localValue, setLocalValue] = useState('');
  const blurTimeoutId = useRef(null);

  const setOnfocus = () => {
    setHasFocus(true);
    onFocus?.();
  };

  const setOnBlur = () => {
    if (!forceFocus) {
      setHasFocus(false);
      setTouched(true);
      blurTimeoutId.current = setTimeout(() => onBlur?.(), ON_BLUR_TIMEOUT);
    }
  };

  const showError = touched && (errorMsg || hasError);

  const showClearButton = showClear && value;

  useEffect(() => {
    setLocalValue(value);
  }, [value]);

  useEffect(() => {
    if (!forceFocus && hasFocus) {
      setOnBlur();
    }
  }, [forceFocus]);

  useEffect(() => {
    if (blurTimeoutId?.current) clearTimeout(blurTimeoutId.current);
  }, []);

  const localOnChange = (val: string) => {
    if (onChange) {
      setLocalValue(val);
      onChange(val);
    }
  };

  return (
    <div
      className={cn(
        'textfield',
        { 'is--active': hasFocus || value },
        { 'has--focus': hasFocus },
        { 'has--icon': icon },
        { 'is--disabled': disabled },
        { 'no--label': !label },
        { 'no--onchange': !onChange },
        { 'has--trailingicon': trailingIcon || showClearButton },
        { 'has--error': showError },
        `size--${size}`
      )}>
      {label && <label className="textfield-label">{label}</label>}
      <div className="textfield-input">
        {icon && <div className="textfield-icon">{icon}</div>}
        <input
          id={name}
          value={localValue || ''}
          onChange={(e) => localOnChange(e.target.value)}
          onBlur={setOnBlur}
          onFocus={setOnfocus}
          autoComplete={autoComplete ? 'on' : 'off'}
          disabled={disabled}
          placeholder={placeholder ?? ''}
        />
        {showClearButton && (
          <div className="textfield-trailingicon has--cursor">
            <ClearIcon onClick={() => onChange('')}></ClearIcon>
          </div>
        )}

        {trailingIcon && <div className="textfield-trailingicon">{trailingIcon}</div>}
      </div>

      {showError && <div className="textfield-error">{errorMsg}</div>}
    </div>
  );
};

export default TextField;
