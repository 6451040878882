import * as React from 'react';
import { times } from '@src/shared/src/util/general';

type Props = {
  value: number;
};

const Rating: React.FC<Props> = ({ value }) =>
  times(
    (idx) => (
      <span key={`stf-${idx}`} className={`stars-rating-icon ${idx + 1 > value ? 'is-empty' : ''}`} />
    ),
    5,
  );

  export default Rating;
