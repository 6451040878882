import * as React from 'react';
import { Route, useRouteMatch } from 'react-router-dom';

// Utils
import { redirectToBackendForLogin } from '@toolkit/util/app';
// Constants
// Services
// Models, Stores & Interfaces
// Components

import {
  ForgotPasswordConn,
  MobileAuthConn,
} from '@pod/admin_user';

const redirectToBackend = () => {
  redirectToBackendForLogin();
  return null;
};

export const AuthRouter: React.FC = () => {
  const match = useRouteMatch();

  return (
    <div>
      <Route path={`${match.url}/login`} render={redirectToBackend} />
      <Route path={`${match.url}/forgot_password`} component={ForgotPasswordConn} />
      <Route path={`${match.url}/mobile/ios`} render={() => <MobileAuthConn os="ios" />}  />
      <Route path={`${match.url}/mobile/android`} render={() => <MobileAuthConn os="android" />}  />
      <Route exact path={`${match.url}/`} render={redirectToBackend} />
    </div>
  );
};

export default AuthRouter;
