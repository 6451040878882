import { createAction, createAsyncAction } from 'typesafe-actions';

// Utils
// Constants
// Actions
// Models
import { HotelModel, HotelFareModel } from '../models';
// Interfaces
import { ActionCallback } from '../interfaces';

export const addHotel = createAction(
  'hotels/ADD_HOTEL',
  (resolve) => (hotel:HotelModel) => resolve(hotel)
);

export const appendHotels = createAction(
  'hotels/APPEND_HOTELS',
  (resolve) => (hotels:HotelModel[]) => resolve(hotels)
);

export const addFilteredHotel = createAction(
  'hotels/ADD_FILTERED_HOTEL',
  (resolve) => (hotel:HotelModel) => resolve(hotel)
);

export const setFilteredHotels = createAction(
  'hotels/SET_FILTERED_HOTELS',
  (resolve) => (hotels:HotelModel[]) => resolve(hotels)
);

export const setHotels = createAction(
  'hotels/SET_HOTELS',
  (resolve) => (hotels:HotelModel[]) => resolve(hotels)
);

export const clearHotels = createAction('hotels/CLEAR_HOTELS');

export const setSelectedHotelId = createAction(
  'hotels/SET_SELECTED_HOTEL_ID',
  (resolve) => (hotelId:string) => resolve(hotelId)
);

export const setLastWSUpdated = createAction(
  'hotels/SET_LAST_WS_UPDATED',
  (resolve) => (date:number) => resolve(date)
);

export const setExpanded = createAction(
  'hotels/SET_EXPANDED',
  (resolve) => (hotelId:string, expanded:boolean) => resolve({ hotelId, expanded })
);

export const setAllowanceMatchingHotelIds = createAction(
  'trips/SET_ALLOWANCE_MATCHING_HOTEL_IDS',
  (resolve) => (allowanceMatchingHotelIds:number[]) => resolve(allowanceMatchingHotelIds)
);

export const setAllowanceOriginalFares = createAction(
  'trips/SET_ALLOWANCE_ORIGINAL_FARES',
  (resolve) => (allowanceOriginalFares: HotelFareModel[]) => resolve(allowanceOriginalFares)
);

// Async actions
export const fetchHotelsAsync = createAsyncAction(
  'hotels/FETCH_HOTELS_REQUEST',
  'hotels/FETCH_HOTELS_SUCCESS',
  'hotels/FETCH_HOTELS_FAILURE',
)<ActionCallback & { searchId:number }, void, void>();

export const fetchHotelDetailsAsync = createAsyncAction(
  'hotels/FETCH_HOTEL_DETAILS_REQUEST',
  'hotels/FETCH_HOTEL_DETAILS_SUCCESS',
  'hotels/FETCH_HOTEL_DETAILS_FAILURE',
)<ActionCallback & { hotelId:string }, void, void>();

export const addHotelFaresToBasketAsync = createAsyncAction(
  'hotels/ADD_HOTEL_FARES_TO_BASKET_REQUEST',
  'hotels/ADD_HOTEL_FARES_TO_BASKET_SUCCESS',
  'hotels/ADD_HOTEL_FARES_TO_BASKET_FAILURE',
)<ActionCallback & { hotelFareIds:string[] }, void, void>();

export const setFavoriteHotelAsync = createAsyncAction(
  'hotels/SET_FAVORITE_HOTEL_REQUEST',
  'hotels/SET_FAVORITE_HOTEL_SUCCESS',
  'hotels/SET_FAVORITE_HOTEL_FAILURE'
)<ActionCallback & { hotelId:string, isFavorite:boolean }, void, void>();
