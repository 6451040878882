import * as React from 'react';
import { useSelector } from 'react-redux';

// Utils
import { isNil } from '@src/shared/src/util/general';
import { useTranslation } from 'react-i18next';
import { hotelHooks, tripHooks } from '@src/services';
// Constants
import { DIRECTION } from '@src/shared/src/const/app';
// Actions
// Models
import {
  HotelFareModel,
  RedeemingAllowanceModel,
  TravelBookingFareModel,
} from '@src/shared/src/models';
// Interfaces
import { IRootState } from '@src/store';
// Components
import { WarningBanner } from '@toolkit/ui';
// Styles

type Props = {
  redeemingAllowance: RedeemingAllowanceModel;
} & (
  | {
      selection: 'transport';
      direction: DIRECTION;
    }
  | { selection: 'hotel' }
);

export const AllowanceSelectionNotification: React.FC<Props> = (props) => {
  const { t } = useTranslation();
  const matchingItem =
    props.selection === 'hotel'
      ? hotelHooks.useAllowanceMatchingHotel()
      : tripHooks.useAllowanceMatchingTrip(props.direction);

  const matchingFares = useSelector((state: IRootState) =>
    props.selection === 'hotel'
      ? state.hotels.allowanceOriginalBookingHotelFares
      : state.trips.allowanceOriginalBookingTripFares,
  );

  if (props.redeemingAllowance) {
    if (isNil(matchingItem)) {
      return (
        <WarningBanner
          icon="lp-icon-help"
          isInfo={true}
          label={t('allowanceSelectionNotification.message.noSelectionFound')}
        />
      );
    }

    // show selected fare information only when a matchedItem is present
    if (matchingFares?.length) {
      return (
        <WarningBanner
          icon="lp-icon-help"
          isInfo={true}
          label={
            <div>
              <p>{t('allowanceSelectionNotification.message.fareAvailabilityWarning')}</p>
              {matchingFares.map((fare: HotelFareModel | TravelBookingFareModel) => (
                <div key={`matching-fare-${fare.id}`}>
                  <p>
                    {'roomType' in fare
                      ? t('allowanceSelectionNotification.message.selectedRoomType', {
                          roomType: fare.roomType,
                        })
                      : t('allowanceSelectionNotification.message.selectedFareName', {
                          fareName: fare.name,
                        })}
                  </p>
                </div>
              ))}
            </div>
          }
        />
      );
    }
  }
};
