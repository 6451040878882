import { createAction, createAsyncAction } from 'typesafe-actions';

// Utils
// Constants
import { DIRECTION } from '../const/app';
// Actions
// Models
import { TripModel, TravelBookingFareModel } from '../models';
// Interfaces
import { ActionCallback } from '../interfaces';

export const addTrips = createAction(
  'trips/ADD_TRIPS',
  (resolve) => (trips:TripModel[], direction:DIRECTION) => resolve({ trips, direction })
);

export const setFilteredTripIds = createAction(
  'trips/SET_FILTERED_TRIP_IDS',
  (resolve) => (tripIds:string[], direction:DIRECTION) => resolve({ tripIds, direction })
);

export const clearTrips = createAction('trips/CLEAR_TRIPS');

export const setTripIsRefreshing = createAction(
  'trips/SET_TRIP_IS_REFRESHING',
  (resolve) => (tripId:number, isRefreshing:boolean) => resolve({ tripId, isRefreshing })
);

export const setSelectedInboundId = createAction(
  'trips/SET_SELECTED_INBOUND_ID',
  (resolve) => (tripId:number) => resolve(tripId)
);

export const setSelectedOutboundId = createAction(
  'trips/SET_SELECTED_OUTBOUND_ID',
  (resolve) => (tripId:number) => resolve(tripId)
);

export const setLastWSUpdated = createAction(
  'trips/SET_LAST_WS_UPDATED',
  (resolve) => (date:number) => resolve(date)
);

export const setExpanded = createAction(
  'trips/SET_EXPANDED',
  (resolve) => (tripId:number, expanded:boolean, direction:DIRECTION) => resolve({ tripId, expanded, direction })
);

export const setAllowanceMatchingTripIds = createAction(
  'trips/SET_ALLOWANCE_MATCHING_TRIP_IDS',
  (resolve) => (allowanceMatchingTripIds:number[]) => resolve(allowanceMatchingTripIds)
);

export const setAllowanceOriginalFares = createAction(
  'trips/SET_ALLOWANCE_ORIGINAL_FARES',
  (resolve) => (allowanceOriginalFares: TravelBookingFareModel[]) => resolve(allowanceOriginalFares)
);

// Async Actions

export const fetchTripsAsync = createAsyncAction(
  'trips/FETCH_TRIPS_REQUEST',
  'trips/FETCH_TRIPS_SUCCESS',
  'trips/FETCH_TRIPS_FAILURE'
)<ActionCallback & { searchId:number, direction:string }, void, void>();

export const fetchTripDetailsAsync = createAsyncAction(
  'trips/FETCH_TRIP_DETAILS_REQUEST',
  'trips/FETCH_TRIP_DETAILS_SUCCESS',
  'trips/FETCH_TRIP_DETAILS_FAILURE'
)<ActionCallback & { tripId:number }, void, void>();

export const changeShuttleTypeAsync = createAsyncAction(
  'trips/CHANGE_SHUTTLE_TYPE_REQUEST',
  'trips/CHANGE_SHUTTLE_TYPE_SUCCESS',
  'trips/CHANGE_SHUTTLE_TYPE_FAILURE'
)<ActionCallback & { vehicle:string, depOrArr:string, direction:string }, void, void>();

export const changeTariffAsync = createAsyncAction(
  'trips/CHANGE_TARIFF_REQUEST',
  'trips/CHANGE_TARIFF_SUCCESS',
  'trips/CHANGE_TARIFF_FAILURE'
)<ActionCallback & { tariffId:string, tripId:number }, void, void>();

export const changeShuttleTimeAsync = createAsyncAction(
  'trips/CHANGE_SHUTTLE_TIME_REQUEST',
  'trips/CHANGE_SHUTTLE_TIME_SUCCESS',
  'trips/CHANGE_SHUTTLE_TIME_FAILURE'
)<ActionCallback & { tripId:number, legOptionId:number, timing:string }, void, void>();
