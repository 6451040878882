import * as React from 'react';

// Utils
import { navigate, requireAsset } from '@toolkit/util/app';
import Bugsnag from '@bugsnag/js';

// Constants
import { API_URL } from '@src/shared/src/const/api';
// Actions, Models & Interfaces
// Utils
// Components
// Styles
import '../styles/Logo.scss';
import { ROUTES } from '@toolkit/const/app';

type Props = {
  language: string;
  orgLogoPath: string;
  orgId: number;
};

const Logo: React.FC<Props> = (props) => {
  const { language, orgLogoPath, orgId } = props;

  const coLogoLP = `logos/secondary-co-brand-${language}.svg`;

  let fallbackCoLogoLP = `logos/secondary-co-brand-en-US.svg`;

  try {
    if (language) {
      fallbackCoLogoLP = requireAsset(coLogoLP) ? coLogoLP : fallbackCoLogoLP;
    }
  } catch (err) {
    Bugsnag.notify(err);
  }

  const mainLogo = `logos/secondary.svg`;

  return (
    <div className="logo" onClick={() => navigate(ROUTES.HOME)}>
      {orgLogoPath ? (
        <>
          <img className="logo-co" width="86" height="36" src={requireAsset(fallbackCoLogoLP)} />
          <img
            className="logo-brand"
            src={`${API_URL.DEFAULT}/logos/${orgId}/${encodeURIComponent(orgLogoPath)}`}
          />
        </>
      ) : (
        <img className="logo-main" width="86" height="36" src={requireAsset(mainLogo)} />
      )}
    </div>
  );
};

export default Logo;
