import * as React from 'react';
import { useDispatch } from 'react-redux';
import { addDays, isBefore, startOfDay } from 'date-fns';

// Utils
import { partial } from '@src/shared/src/util/general';
import { t } from '@toolkit/util/i18n';
import useBreakpoints from '@toolkit/util/useBreakpoints';
// Hooks
import { searchHooks } from '@src/services';
// Constants
import {
  DATE_FORMAT_TYPES,
  DIRECTION,
  MAX_SEARCH_DAYS,
  SEARCH_TYPE,
} from '@src/shared/src/const/app';
// Actions, Models & Interfaces
import { IFilterTimeWindow } from '@src/shared/src/interfaces';
import { setSearchType } from '@src/shared/src/actions/searchActions';
// Components
import { SearchBarSlideOut, SearchBarDateTimeSelector } from '@pod/search/components';
import { Link, TextField } from '@toolkit/ui';
import SearchBarSlideOutNavbar from '@src/pod/search/components/SearchBarSlideOutNavbar';
import { formatDate } from '@src/shared/src/util/date';
import { CalendarIcon } from '@toolkit/ui/icons';
// Styles
require('react-day-picker/lib/style.css');
require('@pod/search/styles/SearchBarDateTime.scss');

type Props = {
  isSearchingForOnlyOutbound: boolean;
  isSearchingForOnlyHotel: boolean;
  uiSearchDepAtOverlay: boolean;
  uiSearchArrAtOverlay: boolean;
  onSetSearchDepAt: (val: Date) => void;
  onSetSearchArrAt: (val: Date) => void;
  onSetUiSearchDepAtOverlay: (val: string, type?: boolean) => void;
  onSetUiSearchArrAtOverlay: (val: string, type?: boolean) => void;
  onSetIsSearchingForOnlyOutbound: (val: boolean) => void;
  searchTimeWindow: IFilterTimeWindow;
  disableToggleButton?: boolean;
  disableDepDatePicker?: boolean;
  disableArrDatePicker?: boolean;
};

const SearchBarDateTime: React.FC<Props> = (props) => {
  const dispatch = useDispatch();
  const { isMd } = useBreakpoints();
  const [searchType] = searchHooks.useSearchState<SEARCH_TYPE>(['currentSearch', 'searchType']);
  const [searchDepAt] = searchHooks.useSearchState<Date>(['currentSearch', 'depAt']);
  const [searchArrAt] = searchHooks.useSearchState<Date>(['currentSearch', 'arrAt']);
  const [searchDepRentalTime] = searchHooks.useSearchState<string>([
    'currentSearch',
    'depRentalTime',
  ]);
  const [searchArrRentalTime] = searchHooks.useSearchState<string>([
    'currentSearch',
    'arrRentalTime',
  ]);

  const isArrivalDateMissing = searchHooks.useIsArrDateMissing();
  const isDepartureDateMissing = searchHooks.useIsDepDateMissing();

  const handleDepArrDate = (isDep: boolean, date: Date) => {
    isDep ? props.onSetSearchDepAt(date) : props.onSetSearchArrAt(date);
    if (isDep && isBefore(searchArrAt, date)) {
      props.onSetSearchArrAt(addDays(date, 3));
    }
  };

  const formatDateTime = (dateTime: Date, isDep: boolean) => {
    if (!dateTime) {
      return '';
    } else if (searchType === SEARCH_TYPE.RENTAL) {
      const rentalTime = isDep ? searchDepRentalTime : searchArrRentalTime;
      return `${formatDate(dateTime, DATE_FORMAT_TYPES.SHORT_DATE)} - ${rentalTime}`;
    } else {
      return formatDate(dateTime, DATE_FORMAT_TYPES.DAY_SHORT_DATE);
    }
  };

  const getToggleInboundDateButton = () => {
    if (searchType !== SEARCH_TYPE.HOTEL && searchType !== SEARCH_TYPE.RENTAL) {
      if (props.isSearchingForOnlyOutbound) {
        return (
          <Link
            onClick={() => {
              dispatch(setSearchType(SEARCH_TYPE.ALL));
              props.onSetIsSearchingForOnlyOutbound(false);
            }}
            className="tcp-search-bar-date-time-link"
            small>
            {t('search.bar.form.button.inbound')}
          </Link>
        );
      } else {
        return (
          <Link
            className="tcp-search-bar-date-time-link"
            onClick={() => {
              dispatch(setSearchType(SEARCH_TYPE.OUTBOUND));
              props.onSetIsSearchingForOnlyOutbound(true);
              handleDepArrDate(false, null);
            }}
            small>
            {t('search.bar.form.button.only_outbound')}
          </Link>
        );
      }
    }
  };

  const renderDateTimeBlockTitle = (isDep: boolean, searchType: SEARCH_TYPE) => {
    switch (searchType) {
      case SEARCH_TYPE.HOTEL:
        return isDep
          ? t('search.bar.form.date.label.checkin')
          : t('search.bar.form.date.label.checkout');
      case SEARCH_TYPE.RENTAL:
        return isDep
          ? t('searchBarDateTime.form.label.pickupTime')
          : t('searchBarDateTime.form.label.returnTime');
      default:
        return isDep
          ? t('search.bar.form.date.label.outbound')
          : t('search.bar.form.date.label.inbound');
    }
  };

  return (
    <div className="tcp-search-bar-date-time">
      <div className="tcp-search-bar-date-time-item">
        <div tabIndex={0}>
          <TextField
            name="date"
            onFocus={() => props.onSetUiSearchDepAtOverlay('depAt')}
            value={formatDateTime(searchDepAt, true)}
            label={renderDateTimeBlockTitle(true, searchType)}
            autoComplete={false}
            hasError={isDepartureDateMissing}
            icon={!isMd && <CalendarIcon />}
            forceFocus={props.uiSearchDepAtOverlay}
            disabled={props.disableDepDatePicker}
          />
        </div>
      </div>
      <div className="tcp-search-bar-date-time-item">
        {!props.disableToggleButton && getToggleInboundDateButton()}
        <TextField
          name="date"
          onFocus={() => props.onSetUiSearchArrAtOverlay('arrAt')}
          value={formatDateTime(searchArrAt, false)}
          label={renderDateTimeBlockTitle(false, searchType)}
          autoComplete={false}
          hasError={isArrivalDateMissing}
          icon={!isMd && <CalendarIcon />}
          disabled={
            (props.isSearchingForOnlyOutbound && !props.isSearchingForOnlyHotel) ||
            props.disableArrDatePicker
          }
          forceFocus={props.uiSearchArrAtOverlay}
        />
      </div>
      <SearchBarSlideOut
        isOpen={props.uiSearchDepAtOverlay}
        onClose={() => props.onSetUiSearchDepAtOverlay('depAt', false)}
        className="is--datetime is--outbound">
        {isMd ? (
          <SearchBarSlideOutNavbar
            onClose={() => props.onSetUiSearchDepAtOverlay('depAt', false)}
          />
        ) : null}
        <SearchBarDateTimeSelector
          searchType={searchType}
          show={props.uiSearchDepAtOverlay}
          direction={DIRECTION.OUTWARD}
          minDate={startOfDay(new Date())}
          onConfirm={() => props.onSetUiSearchDepAtOverlay('')}
          value={searchDepAt}
          onDateChange={partial(handleDepArrDate, [true])}
          selectedDepTimeWindow={props.searchTimeWindow.outward.dep.timeWindow}
          selectedArrTimeWindow={props.searchTimeWindow.outward.arr.timeWindow}
          maxDate={addDays(new Date(), MAX_SEARCH_DAYS)}
        />
      </SearchBarSlideOut>
      <SearchBarSlideOut
        isOpen={props.uiSearchArrAtOverlay}
        onClose={() => props.onSetUiSearchDepAtOverlay('arrAt', false)}
        className="is--datetime is--inbound">
        {isMd ? (
          <SearchBarSlideOutNavbar
            onClose={() => props.onSetUiSearchDepAtOverlay('arrAt', false)}
          />
        ) : null}
        <SearchBarDateTimeSelector
          searchType={searchType}
          show={props.uiSearchArrAtOverlay}
          direction={DIRECTION.INBOUND}
          minDate={searchDepAt}
          maxDate={addDays(new Date(), MAX_SEARCH_DAYS)}
          onConfirm={() => props.onSetUiSearchArrAtOverlay('')}
          value={searchArrAt}
          onDateChange={partial(handleDepArrDate, [false])}
          selectedDepTimeWindow={props.searchTimeWindow.inbound.dep.timeWindow}
          selectedArrTimeWindow={props.searchTimeWindow.inbound.arr.timeWindow}
          onMinDateNullErrorMsg={t(
            'searchBarDateTime.searchBarDateTimeSelector.inbound.onMinDateNullErrorMsg',
          )}
        />
      </SearchBarSlideOut>
    </div>
  );
};

export default SearchBarDateTime;
