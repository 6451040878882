import * as React from 'react';

// Utils
import useBreakpoints from '@toolkit/util/useBreakpoints';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

// Actions, Models & Interfaces
import { setUiMobileFilterOverlay, setUiMobileNoScrolling } from '@pod/ui/actions';

// Components
import Navbar from './Navbar';

type Props = React.PropsWithChildren & {
  isOpen: boolean;
};

const Sidebar: React.FC<Props> = (props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { isMd } = useBreakpoints();
  const { isOpen, children } = props;

  const closeMobileFilterOverlay = () => {
    dispatch(setUiMobileFilterOverlay(false));
    dispatch(setUiMobileNoScrolling(false));
  };

  return (
    <aside className={`sidebar sidebar-trip ${isOpen ? 'is--open' : ''}`}>
      <div className="sidebar-wrapper">
        {isMd && (
          <Navbar
            showBackButton={false}
            actionRight={() => closeMobileFilterOverlay()}
            labelRight={t('search.bar.slide.out.button.confirm')}></Navbar>
        )}
        {children}
      </div>
    </aside>
  );
};

export default Sidebar;
