import { ActionType } from 'typesafe-actions';

import * as adminUserActions from './adminUserActions';
import * as appSettingsActions from './appSettingsActions';
import * as basketActions from './basketActions';
import * as checkoutActions from './checkoutActions';
import * as filterActions from './filterActions';
import * as generalActions from './generalActions';
import * as hotelActions from './hotelActions';
import * as searchActions from './searchActions';
import * as settingsActions from './settingsActions';
import * as tripsActions from './tripsActions';
import * as organizationActions from './organizationActions';
import * as usersActions from './usersActions';
import * as authActions from './authActions';

type AdminUserActions = ActionType<typeof adminUserActions>;
type AppSettingsActions = ActionType<typeof appSettingsActions>;
type BasketActions = ActionType<typeof basketActions>;
type CheckoutActions = ActionType<typeof checkoutActions>;
type FilterActions = ActionType<typeof filterActions>;
type GeneralActions = ActionType<typeof generalActions>;
type HotelActions = ActionType<typeof hotelActions>;
type SearchActions = ActionType<typeof searchActions>;
type SettingsActions = ActionType<typeof settingsActions>;
type TripsActions = ActionType<typeof tripsActions>;
type OrganizationActions = ActionType<typeof organizationActions>;
type UsersActions = ActionType<typeof usersActions>;
type AuthActions = ActionType<typeof authActions>;

export {
  AdminUserActions,
  adminUserActions,
  AppSettingsActions,
  appSettingsActions,
  BasketActions,
  basketActions,
  checkoutActions,
  CheckoutActions,
  filterActions,
  FilterActions,
  generalActions,
  GeneralActions,
  hotelActions,
  HotelActions,
  SearchActions,
  searchActions,
  settingsActions,
  SettingsActions,
  TripsActions,
  tripsActions,
  OrganizationActions,
  organizationActions,
  UsersActions,
  usersActions,
  AuthActions,
  authActions,
};