import * as React from 'react';

// Utils
import { tripHooks } from '@src/services';
import i18n from '@src/toolkit/util/i18n';
// Constants
import {
  DIRECTION,
} from '@src/shared/src/const/app';
// Actions
// Models
// Interfaces

type Props = {
  direction:DIRECTION;
};

export const TripHeader: React.FC<Props> = (props) => {
  const tripsCount = tripHooks.useTripsCount(props.direction);
  const filteredTripsCount = tripHooks.useFilteredTripsCount(props.direction);
  return (
    <div className="content-title">
      {i18n.t(`tripsConn.trips.header.${props.direction}`)}&nbsp;
      {i18n.t('global.results.label.of', {
        total: String(tripsCount), filtered: String(filteredTripsCount)})}
    </div>
  );
};
export default TripHeader;
