import * as React from 'react';

// Utils
import { path } from '@src/shared/src/util/general';
import { getTripFirstTS, getTripLastTS, getMainLegs } from '@src/shared/src/util/trips';
import { getTripOperatorImageURL } from '@toolkit/util/app';
import { formatDate } from '@src/shared/src/util/date';
// Constants
import { DATE_FORMAT_TYPES } from '@src/shared/src/const/app';
// Actions
// Models
import { TripModel } from '@src/shared/src/models';
// Interfaces
// Components
// Styles
import '../styles/TripSideBarInfo.scss';

type Props = {
  trip: TripModel;
};

const TripSideBarInfo: React.FC<Props> = (props: Props) => {
  const tripMainLegs = getMainLegs(props.trip.legs);
  const firstTS = getTripFirstTS(tripMainLegs);
  const lastTS = getTripLastTS(tripMainLegs);
  return (
    <div className="lp-trip-sidebar-info">
      <img className="lp-trip-sidebar-info-logo" src={getTripOperatorImageURL(props.trip)} />
      <div>
        {`${formatDate(path(['depAt'], firstTS), DATE_FORMAT_TYPES.DAY_LONG_DATE)},
            ${formatDate(path(['depAt'], firstTS), DATE_FORMAT_TYPES.LONG_TIME)} -
            ${formatDate(path(['arrAt'], lastTS), DATE_FORMAT_TYPES.LONG_TIME)}`}
        <div>{`${path(['depName'], firstTS)} - ${path(['arrName'], lastTS)}`}</div>
      </div>
    </div>
  );
};

export default TripSideBarInfo;
