import * as React from 'react';
import { useSelector } from 'react-redux';
import { Trans } from 'react-i18next';

// Utils
import { t } from '@toolkit/util/i18n';
import { isAnyBookingItemAirbnb } from '@src/shared/src/selectors/checkoutSelectors';
// Constants
import { STATUS } from '@src/shared/src/const/app';
import { API_URL } from '@src/shared/src/const/api';
// Actions
// Models & Interfaces
// Components
import { Link } from '@toolkit/ui';
import { CalendarIcon, TicketIcon } from '@toolkit/ui/icons';
// Styles
import '../styles/CheckoutSidebarConfirmBody.scss';

type Props = {
  basketStatus: STATUS;
  bookingId: string;
};

export const CheckoutSidebarConfirmBody: React.FC<Props> = ({ basketStatus, bookingId }) => {
  const bookingHasAirbnb = useSelector(isAnyBookingItemAirbnb);

  const renderICal = () => {
    if ((window as any).__IS_WEBVIEW) {
      return (
        <p>
          <strong>{t('checkoutSideBarConfirmationInfo.text.ios.addToCalendar')}</strong>
        </p>
      );
    } else {
      return (
        <a className="tcp-link" href={`${API_URL.ORG_ADMIN_TRAVEL_BOOKINGS}/${bookingId}/ics`}>
          <CalendarIcon />
          {t('checkoutSideBarConfirmationInfo.button.addToCalendar')}
        </a>
      );
    }
  };

  return (
    <div className="sidebar-inner">
      <div className="sidebar-section-inner">
        <div className="checkout-sidebar-confirm-body-title">
          {t('checkoutSideBarConfirmationInfo.title.prepareTrip')}
        </div>
        {bookingHasAirbnb
          ? t('checkout-sidebar-confirm-body.text.description-start-airbnb')
          : t('checkout-sidebar-confirm-body.text.description-start')}
        <br />
        <Trans i18nKey="checkout-sidebar-confirm-body.text.description">
          <>
            <br />
            <br />
          </>
        </Trans>
        <br />
        <br />
      </div>

      {basketStatus === STATUS.CONFIRM_FINISHED && renderICal()}

      <Link
        onClick={() => window.location.assign(`${API_URL.DEFAULT}/org_admin/travel/bookings`)}
        icon={<TicketIcon />}>
        {t('checkoutSideBarConfirmationInfo.button.seeAllBookings')}
      </Link>
    </div>
  );
};
