import * as React from 'react';

// Utils
import { formatDate, formatDateRange } from '@src/shared/src/util/date';
import { navigate } from '@toolkit/util/app';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

// Constants
import { DATE_FORMAT_TYPES } from '@src/shared/src/const/app';
// Actions

// Models
import { SettingsModel, RedeemingAllowanceModel } from '@src/shared/src/models';
// Interfaces
import { IRootState } from '@src/store';
// Components
import { Button, SidebarRoute } from '@toolkit/ui';
import SearchSideBarPassengerInfo from './SearchSideBarPassengerInfo';
import { CalendarIcon, PinIcon } from '@toolkit/ui/icons';
// Styles

type Props = {
  to: string;
  depAt: Date;
  arrAt: Date;
  loyaltyCards: SettingsModel[];
  from?: string;
  hideResetButton?: boolean;
  isSearchingForOnlyHotel?: boolean;
  redeemingAllowance?: RedeemingAllowanceModel;
};

const SearchSideBarInfo: React.FC<Props> = (props) => {
  const redeemingAllowance = useSelector((state: IRootState) => state.checkout.redeemingAllowance)
  const hideResetBtn = props.hideResetButton || redeemingAllowance;
  const { t } = useTranslation();

  return (
    <div className="sidebar-inner hidden-xs-down">
      <div className="sidebar-title">{t('TripSideBarInfo.title')}</div>
      {props.from && !props.isSearchingForOnlyHotel && (
        <SidebarRoute icon={<PinIcon />}>{props.from}</SidebarRoute>
      )}
      {props.to && <SidebarRoute icon={<PinIcon />}>{props.to}</SidebarRoute>}
      {props.depAt && (
        <SidebarRoute icon={<CalendarIcon />}>
          {props.arrAt
            ? formatDateRange(props.depAt, props.arrAt, '-', DATE_FORMAT_TYPES.DAY_SHORT_DATE)
            : formatDate(props.depAt, DATE_FORMAT_TYPES.DAY_SHORT_DATE)}
        </SidebarRoute>
      )}
      <SearchSideBarPassengerInfo loyaltyCards={props.loyaltyCards} />
      {!hideResetBtn && (
        <Button onClick={() => navigate('/')} className="sidebar-reset button button-light">
          {t('TripSideBarInfo.button.reset')}
        </Button>
      )}
    </div>
  );
};

export default SearchSideBarInfo;
