import * as React from 'react';

// Utils
// Constants
// Actions
// Models
import { TripModel } from '@src/shared/src/models';
// Interfaces
// Components
import { TripSideBarInfo } from '@pod/trips/components';
import { PencilIcon } from '@toolkit/ui/icons';
// Styles

type Props = {
  title: string;
  trip: TripModel;
  onEdit: () => void;
};

const CheckoutSideBarTripInfo: React.FC<Props> = (props: Props) => {
  return (
    <div className="sidebar-inner">
      <div className="sidebar-title">
        {props.title}
        {props.onEdit && (
          <button onClick={props.onEdit}>
            <PencilIcon />
          </button>
        )}
      </div>

      <TripSideBarInfo trip={props.trip} />
    </div>
  );
};

export default CheckoutSideBarTripInfo;
