import * as React from 'react';
import { useContext } from 'react';
import { useDispatch } from 'react-redux';

// Utils
import { t } from '@toolkit/util/i18n';
import { AppContext } from '@toolkit/util/AppContext';
import { searchHooks } from '@src/services';
// Constants
import { SEARCH_TYPE } from '@src/shared/src/const/app';
// Actions
import { searchActions } from '@src/shared/src/actions';
// Models
import { models } from '@src/shared/src';
// Interfaces
// Components
import SearchBarSlideOutNavbar from '@src/pod/search/components/SearchBarSlideOutNavbar';
import {
  SearchBarRouteInput,
  SearchBarSlideOut,
  SearchBarRouteSelector,
  RentalOnlySearch,
} from '@pod/search/components';
// Styles
import '@pod/search/styles/SearchBarOptions.scss';

type Props = {
  isSearchingForOnlyHotel: boolean;
  uiSearchSuggestDepOverlay: boolean;
  uiSearchSuggestArrOverlay: boolean;
  depSuggestions: models.SearchNodeModel[];
  arrSuggestions: models.SearchNodeModel[];
  homeLocations: models.OptionModel;
  workLocations: models.OptionModel;
  favoriteLocations: models.OptionModel[];
  onSetUiSearchSuggestDepOverlay: (val: string, type?: boolean) => void;
  onSetUiSearchSuggestArrOverlay: (val: string, type?: boolean) => void;
  onSetIsSearchingForOnlyHotel: (val: boolean) => void;
  onSetIsSearchingForOnlyOutbound: (val: boolean) => void;
  onFetchLocationDetail: (isDep: boolean, location: models.SearchNodeModel) => void;
  profile: models.UserModel;
  transportInputDisabled?: boolean;
};

export const SearchBarRoute: React.FC<Props> = (props) => {
  const dispatch = useDispatch();
  const appContext = useContext(AppContext);
  const [searchType] = searchHooks.useSearchState<SEARCH_TYPE>(['currentSearch', 'searchType']);
  const [searchArr] = searchHooks.useSearchState<string>(['currentSearch', 'arrName']);
  const [searchDep] = searchHooks.useSearchState<string>(['currentSearch', 'depName']);
  const [rentalReturnAtPickup] = searchHooks.useSearchState<boolean>([
    'currentSearch',
    'rentalReturnAtPickup',
  ]);
  const isArrivalMissing = searchHooks.useIsArrMissing();
  const isDepartureMissing = searchHooks.useIsDepMisisng();

  const setSearchArrival = (val: string) => {
    dispatch(searchActions.setSearchArrCoor(-1, -1));
    dispatch(searchActions.setSearchArr(val));
  };

  const setSearchDeparture = (val: string) => {
    dispatch(searchActions.setSearchDepCoor(-1, -1));
    dispatch(searchActions.setSearchDep(val));
  };

  const renderSearchBarRouteInput = (
    type: string,
    searchType: SEARCH_TYPE,
    inNavbar: boolean = false,
  ) => {
    switch (searchType) {
      case SEARCH_TYPE.HOTEL:
        return (
          <div className="tcp-search-bar-route tcp-search-bar-route-active">
            <SearchBarRouteInput
              isFrom={false}
              value={searchArr}
              onFocus={() => props.onSetUiSearchSuggestArrOverlay('arrSuggest')}
              onChange={setSearchArrival}
              displayErrorMsg={isArrivalMissing}
              errorMessage={t('searchBarRoute.form.input.hotelArrival.errorMessage')}
              placeholder={t('search.bar.form.input.placeholder.location')}
              inSlideout={type ? true : false}
            />
          </div>
        );
      case SEARCH_TYPE.RENTAL:
        return (
          <RentalOnlySearch
            onSetSearchArr={setSearchArrival}
            onSetSearchDep={setSearchDeparture}
            onSetUiSearchSuggestArrOverlay={props.onSetUiSearchSuggestArrOverlay}
            onSetUiSearchSuggestDepOverlay={props.onSetUiSearchSuggestDepOverlay}
            isDepartureMissing={isDepartureMissing}
            isArrivalMissing={isArrivalMissing}
            uiSearchSuggestArrOverlay={props.uiSearchSuggestArrOverlay}
            uiSearchSuggestDepOverlay={props.uiSearchSuggestDepOverlay}
            slideOutType={type}
            searchArr={searchArr}
            searchDep={searchDep}
            returnAtPickup={rentalReturnAtPickup}
            setReturnAtPickup={(val) => dispatch(searchActions.setSearchRentalReturnAtPickup(val))}
            inNavbar={inNavbar}
          />
        );
      default:
        return (
          <>
            {!type || type === 'outbound' ? (
              <SearchBarRouteInput
                isFrom={true}
                value={searchDep}
                onFocus={() => props.onSetUiSearchSuggestDepOverlay('depSuggest')}
                onChange={setSearchDeparture}
                placeholder={t('search.bar.form.input.placeholder.from')}
                displayErrorMsg={isDepartureMissing}
                errorMessage={t('searchBarConn.notify.text.departureMissing')}
                isVisible={props.uiSearchSuggestDepOverlay}
                inSlideout={type ? true : false}
                disabled={props.transportInputDisabled}
              />
            ) : (
              ''
            )}
            {!type || type === 'inbound' ? (
              <SearchBarRouteInput
                isFrom={false}
                value={searchArr}
                onFocus={() => props.onSetUiSearchSuggestArrOverlay('arrSuggest')}
                onChange={setSearchArrival}
                placeholder={t('search.bar.form.input.placeholder.to')}
                displayErrorMsg={isArrivalMissing}
                errorMessage={t('searchBarConn.notify.text.arrivalMissing')}
                isVisible={props.uiSearchSuggestArrOverlay}
                inSlideout={type ? true : false}
                disabled={props.transportInputDisabled}
              />
            ) : (
              ''
            )}
          </>
        );
    }
  };

  const onSuggestionSelected = (isDep: boolean, location: models.SearchNodeModel) => {
    // onSetUiSearchSuggestDepOverlay and onSetUiSearchSuggestArrOverlay are same func
    // TODO: Should we pass just one?
    props.onFetchLocationDetail(isDep, location);
    props.onSetUiSearchSuggestDepOverlay('');
  };

  return (
    <div className="tcp-search-bar-route">
      {renderSearchBarRouteInput(null, searchType)}

      <SearchBarSlideOut
        isOpen={props.uiSearchSuggestDepOverlay}
        onClose={() => props.onSetUiSearchSuggestDepOverlay('')}
        className="is--route">
        {!appContext.isMediaBPNotMobile ? (
          <>
            <SearchBarSlideOutNavbar
              onClose={() => props.onSetUiSearchSuggestDepOverlay('')}
              hideConfirmButton
            />
            {renderSearchBarRouteInput('outbound', searchType, true)}
          </>
        ) : null}
        <SearchBarRouteSelector
          isDep={true}
          searchStr={searchDep}
          suggestions={props.depSuggestions}
          homeLocations={props.homeLocations}
          workLocations={props.workLocations}
          favoriteLocations={props.favoriteLocations}
          onSuggestionSelected={(val) => onSuggestionSelected(true, val)}
          profileId={props.profile.id}
        />
      </SearchBarSlideOut>
      <SearchBarSlideOut
        isOpen={props.uiSearchSuggestArrOverlay}
        onClose={() => props.onSetUiSearchSuggestArrOverlay('')}
        className="is--route">
        {!appContext.isMediaBPNotMobile ? (
          <>
            <SearchBarSlideOutNavbar
              onClose={() => props.onSetUiSearchSuggestArrOverlay('')}
              hideConfirmButton
            />
            {renderSearchBarRouteInput('inbound', searchType, true)}
          </>
        ) : null}
        <SearchBarRouteSelector
          isDep={false}
          searchStr={searchArr}
          suggestions={props.arrSuggestions}
          homeLocations={props.homeLocations}
          workLocations={props.workLocations}
          favoriteLocations={props.favoriteLocations}
          onSuggestionSelected={(val) => onSuggestionSelected(false, val)}
          profileId={props.profile.id}
        />
      </SearchBarSlideOut>
    </div>
  );
};
export default SearchBarRoute;
