import * as React from 'react';
import useClickOutside from '@toolkit/util/useClickOutside';
import useBreakpoints from '@toolkit/util/useBreakpoints';

import '../styles/SearchBarSlideOut.scss';

type Props = {
  isOpen: boolean;
  children: any;
  onClose: () => void;
  className?: string;
};

const SearchBarSlideOut: React.FC<Props> = (props) => {
  const { isOpen, className, onClose, children } = props;
  const { isMd } = useBreakpoints();
  const defautlRef = React.useRef<HTMLElement>();

  const onClick = () => {
    onClose();
  };

  const clickOutsideRef = useClickOutside(onClick, 'INPUT');

  return (
    <aside
      className={`searchbar-slide-out ${isOpen ? 'is--open' : ''} ${className}`}
      ref={!isMd && isOpen ? clickOutsideRef : defautlRef}>
      {children}
    </aside>
  );
};

export default SearchBarSlideOut;
