import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { requireAsset } from '@toolkit/util/app';
import './styles/ZendeskDemoChat.scss';

export const ZendeskDemoChat: React.FC = () => {
  const { t } = useTranslation();
  return (
    <div className='zendesk-demo-chat'>
      <img src={requireAsset('zendesk-chat-demo.png')} data-rh={t('ZendeskDemoChat.tooltip')} data-rh-at="top" />
    </div>
  )
}

export default ZendeskDemoChat;
