import * as React from 'react';

// Utils
import { t } from '@toolkit/util/i18n';
// Constants
// Actions
// Models
import { TripModel } from '@src/shared/src/models';
// Interfaces
// Components
import TripSideBarInfo from './TripSideBarInfo';

type Props = {
  outward: TripModel;
  inbound?: TripModel;
  searchId: number;
  cheapestInbound?: number;
};

const TripSideBarSelectionInfo: React.FC<Props> = (props) => {
  return (
    <div className="sidebar-inner">
      <div className="sidebar-title">{t('tripSideBarOutwardInfo.title.selection')}</div>
      <TripSideBarInfo trip={props.outward} />

      {props.inbound && <TripSideBarInfo trip={props.inbound}/>}
    </div>
  );
};

export default TripSideBarSelectionInfo;
