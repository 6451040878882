export default [
  {
    ident: 'N0',
    name: 'Norse Atlantic Airways'
  },
  {
    ident: 'THALYS',
    name: 'THALYS'
  },
  {
    ident: 'SNCF',
    name: 'SNCF'
  },
  {
    ident: 'TGV LYRIA',
    name: 'TGV LYRIA'
  },
  {
    ident: 'RENFE-SNCF',
    name: 'RENFE-SNCF'
  },
  {
    ident: 'CFF',
    name: 'CFF'
  },
  {
    ident: 'EUROSTAR',
    name: 'EUROSTAR'
  },
  {
    ident: 'SBB',
    name: 'SBB'
  },
  {
    ident: 'FFS',
    name: 'FFS'
  },
  {
    ident: 'Flixbus',
    name: 'Flixbus'
  },
  {
    ident: 'Flixtrain',
    name: 'Flixtrain'
  },
  {
    ident: 'DeutscheBahn',
    name: 'DeutscheBahn'
  },
  {
    ident: 'DeutscheBahn_BMIS',
    name: 'DB bahn.business'
  },
  {
    ident: '_B',
    name: 'TUIFly'
  },
  {
    ident: '_C',
    name: 'Alltours'
  },
  {
    ident: '_D',
    name: 'Bucher'
  },
  {
    ident: '_E',
    name: 'FTI'
  },
  {
    ident: '_F',
    name: 'ITS'
  },
  {
    ident: '_G',
    name: 'Jahn Reisen'
  },
  {
    ident: '_H',
    name: 'Neckermann'
  },
  {
    ident: '_I',
    name: 'Schauinsland'
  },
  {
    ident: '_J',
    name: 'Thomas Cook'
  },
  {
    ident: '_K',
    name: '5 vor Flug'
  },
  {
    ident: '_L',
    name: 'Olimar'
  },
  {
    ident: '_M',
    name: 'Öger'
  },
  {
    ident: '_N',
    name: 'Bentour Türkei Reisen AG'
  },
  {
    ident: '_O',
    name: 'Ecco Reisen'
  },
  {
    ident: '_P',
    name: 'Phoenix Reisen'
  },
  {
    ident: '0A',
    name: 'Amber Air'
  },
  {
    ident: '0B',
    name: 'Blue Air Transport Aerian'
  },
  {
    ident: '0D',
    name: 'Darwin Airline'
  },
  {
    ident: '0I',
    name: 'Lan Ssci Trng'
  },
  {
    ident: '0O',
    name: 'Sta Travel'
  },
  {
    ident: '0P',
    name: 'Palau Asia Pacific Air'
  },
  {
    ident: '0V',
    name: 'Vietnam Air Service Company'
  },
  {
    ident: 11,
    name: 'Bulgaria Air Charter'
  },
  {
    ident: 12,
    name: 'VIM Airlines'
  },
  {
    ident: 13,
    name: 'Small Planet'
  },
  {
    ident: 14,
    name: 'Express Airways'
  },
  {
    ident: 15,
    name: 'Sea Air'
  },
  {
    ident: 16,
    name: 'Maleth-Aero'
  },
  {
    ident: '1A',
    name: 'Amadeus'
  },
  {
    ident: '1B',
    name: 'Abacus'
  },
  {
    ident: '1C',
    name: 'EDS Information'
  },
  {
    ident: '1D',
    name: 'Digital Corp'
  },
  {
    ident: '1E',
    name: 'Travelsky Technology'
  },
  {
    ident: '1F',
    name: 'Infini'
  },
  {
    ident: '1G',
    name: 'Galileo'
  },
  {
    ident: '1H',
    name: 'Siren Tvl Joint Stock'
  },
  {
    ident: '1J',
    name: 'Axess International Network Inc.'
  },
  {
    ident: '1K',
    name: 'Southern Cross'
  },
  {
    ident: '1L',
    name: 'West Air Express'
  },
  {
    ident: '1M',
    name: 'JSC Transport'
  },
  {
    ident: '1N',
    name: 'Navitaire Inc.'
  },
  {
    ident: '1P',
    name: 'Worldspan'
  },
  {
    ident: '1S',
    name: 'Sabre'
  },
  {
    ident: '1U',
    name: 'ITA'
  },
  {
    ident: '1V',
    name: 'Galileo Intl Covia'
  },
  {
    ident: '1W',
    name: 'Sabre'
  },
  {
    ident: '1X',
    name: 'Branson Air Express'
  },
  {
    ident: '1Y',
    name: 'Electronic Data Systems'
  },
  {
    ident: '1Z',
    name: 'Fantasia'
  },
  {
    ident: '2A',
    name: 'Deutsche Bahn AG'
  },
  {
    ident: '2B',
    name: 'Ata Aerocondor'
  },
  {
    ident: '2C',
    name: 'SNCF French Railways'
  },
  {
    ident: '2D',
    name: 'Dynamic Airways'
  },
  {
    ident: '2E',
    name: 'Smokey Bay Air Inc.'
  },
  {
    ident: '2F',
    name: 'Frontier Flying Service'
  },
  {
    ident: '2G',
    name: 'Northwest Seaplanes'
  },
  {
    ident: '2H',
    name: 'Thalys International Railways'
  },
  {
    ident: '2I',
    name: 'Star Peru'
  },
  {
    ident: '2J',
    name: 'Air Burkina'
  },
  {
    ident: '2K',
    name: 'Aerogal'
  },
  {
    ident: '2L',
    name: 'Helvetic Airways AG'
  },
  {
    ident: '2M',
    name: 'Moldavian Airlines'
  },
  {
    ident: '2N',
    name: 'National Airlines'
  },
  {
    ident: '2O',
    name: 'Alaska Air Taxi'
  },
  {
    ident: '2P',
    name: 'Air Philippines'
  },
  {
    ident: '2Q',
    name: 'Avitrans Nordic'
  },
  {
    ident: '2R',
    name: 'Via Rail Canada'
  },
  {
    ident: '2S',
    name: 'Star Equatorial Airlines'
  },
  {
    ident: '2T',
    name: 'Alajnihah Airtransport'
  },
  {
    ident: '2U',
    name: 'Sundor International Airlines'
  },
  {
    ident: '2V',
    name: 'Amtrak'
  },
  {
    ident: '2W',
    name: 'Welcome Air'
  },
  {
    ident: '2X',
    name: 'Airway Express Inc.'
  },
  {
    ident: '2Y',
    name: 'Air Andaman Corporation'
  },
  {
    ident: '2Z',
    name: 'Joint Stock Co Voronezh'
  },
  {
    ident: '3A',
    name: 'Chu Kong Passenger Trans'
  },
  {
    ident: '3B',
    name: 'Job Air - Centralnnect Airlines'
  },
  {
    ident: '3D',
    name: 'Denim Air'
  },
  {
    ident: '3E',
    name: 'Air Choice One'
  },
  {
    ident: '3F',
    name: 'Pacific Airways Inc'
  },
  {
    ident: '3G',
    name: 'Atlant-Soyuz Airlines'
  },
  {
    ident: '3H',
    name: 'Air Inuit Ltee'
  },
  {
    ident: '3I',
    name: 'Aerolineas Austral Chile S.a.'
  },
  {
    ident: '3K',
    name: 'Jetstar Asia Airways Pte'
  },
  {
    ident: '3L',
    name: 'Intersky Luftfahrt'
  },
  {
    ident: '3M',
    name: 'Gulfstream Intl Airlines'
  },
  {
    ident: '3N',
    name: 'Air Urga'
  },
  {
    ident: '3O',
    name: 'Air Arabia Maroc'
  },
  {
    ident: '3P',
    name: 'Tiara Air Aruba'
  },
  {
    ident: '3Q',
    name: 'Carib Avaition'
  },
  {
    ident: '3R',
    name: 'Gromov Air'
  },
  {
    ident: '3S',
    name: 'Air Antilles'
  },
  {
    ident: '3T',
    name: 'Turan Air Airline'
  },
  {
    ident: '3U',
    name: 'Sichuan Airlines'
  },
  {
    ident: '3V',
    name: 'TNT Airways SA'
  },
  {
    ident: '3W',
    name: 'Euromanx'
  },
  {
    ident: '3X',
    name: 'Japan Air Commuter'
  },
  {
    ident: '3Z',
    name: 'Everts Air'
  },
  {
    ident: '4A',
    name: 'Air Kiribati Limited'
  },
  {
    ident: '4B',
    name: 'Boutique Air'
  },
  {
    ident: '4C',
    name: 'Aires'
  },
  {
    ident: '4D',
    name: 'Air Sinai'
  },
  {
    ident: '4E',
    name: 'Tanana Air Service'
  },
  {
    ident: '4F',
    name: 'Air-City'
  },
  {
    ident: '4G',
    name: 'Gazpromavia Aviation'
  },
  {
    ident: '4H',
    name: 'United Airway Bangladesh'
  },
  {
    ident: '4J',
    name: 'Somon Air'
  },
  {
    ident: '4K',
    name: 'Kenn Borek Air Ltd.'
  },
  {
    ident: '4L',
    name: 'Georgian International Airlines'
  },
  {
    ident: '4M',
    name: 'Lanargentina'
  },
  {
    ident: '4N',
    name: 'Air North'
  },
  {
    ident: '4O',
    name: 'Interjet'
  },
  {
    ident: '4P',
    name: 'Viking Airlines AB'
  },
  {
    ident: '4Q',
    name: 'Safi Airways'
  },
  {
    ident: '4R',
    name: 'Hi Hamburg International'
  },
  {
    ident: '4S',
    name: 'Sol Air'
  },
  {
    ident: '4T',
    name: 'Belair Airlines Ltd.'
  },
  {
    ident: '4U',
    name: 'Eurowings'
  },
  {
    ident: '4V',
    name: 'Birdy Airlines S.A.'
  },
  {
    ident: '4W',
    name: 'Warbelows Air Ventures'
  },
  {
    ident: '4X',
    name: 'Eurowings operated by Avion Express Malta'
  },
  {
    ident: '4Y',
    name: 'Flight Alaska'
  },
  {
    ident: '4Z',
    name: 'SA Airlink'
  },
  {
    ident: '5A',
    name: 'Alpine Aviation'
  },
  {
    ident: '5B',
    name: 'Chanchangi Airlines'
  },
  {
    ident: '5C',
    name: 'Nature Air'
  },
  {
    ident: '5D',
    name: 'Aeromexiconnect'
  },
  {
    ident: '5E',
    name: 'Equaflight Services'
  },
  {
    ident: '5F',
    name: 'Fly One'
  },
  {
    ident: '5G',
    name: 'Skyservice Airlines'
  },
  {
    ident: '5H',
    name: 'Five Forty Aviation'
  },
  {
    ident: '5I',
    name: 'Alsa Grupo Slu'
  },
  {
    ident: '5J',
    name: 'Cebu Pacific'
  },
  {
    ident: '5K',
    name: 'Hi Fly Transportes S.A'
  },
  {
    ident: '5L',
    name: 'Aerosur S A'
  },
  {
    ident: '5M',
    name: 'Montserrat Airways Ltd.'
  },
  {
    ident: '5N',
    name: 'JSC Nordavia'
  },
  {
    ident: '5O',
    name: 'Europe Airpost'
  },
  {
    ident: '5P',
    name: 'Aerolinea Principal Chile Sa'
  },
  {
    ident: '5Q',
    name: 'BQB Lineas Aereas'
  },
  {
    ident: '5R',
    name: 'Irs Aero'
  },
  {
    ident: '5S',
    name: 'Servicios Aereos'
  },
  {
    ident: '5T',
    name: 'Canadian North'
  },
  {
    ident: '5U',
    name: 'Lineas Aereas Del Estado'
  },
  {
    ident: '5V',
    name: 'Lviv'
  },
  {
    ident: '5W',
    name: 'Astraeus'
  },
  {
    ident: '5X',
    name: 'UPS'
  },
  {
    ident: '5Y',
    name: 'Atlas Air'
  },
  {
    ident: '5Z',
    name: 'Gonini Air SVC'
  },
  {
    ident: '6A',
    name: 'Aviacsa'
  },
  {
    ident: '6B',
    name: 'Britannia Airways AB'
  },
  {
    ident: '6C',
    name: 'Cape Smythe Air Service'
  },
  {
    ident: '6E',
    name: 'Indigo Air'
  },
  {
    ident: '6F',
    name: 'Aerotrans Airlines Ltd.'
  },
  {
    ident: '6G',
    name: 'Sun Air Express'
  },
  {
    ident: '6H',
    name: 'Israir Airlines And Tourism'
  },
  {
    ident: '6I',
    name: 'Air Alsie'
  },
  {
    ident: '6J',
    name: 'Jubba Airways'
  },
  {
    ident: '6K',
    name: 'Kyrgyz Trans Avia Airline'
  },
  {
    ident: '6L',
    name: 'Aklak Inc.'
  },
  {
    ident: '6M',
    name: 'Euroair Ltd.'
  },
  {
    ident: '6N',
    name: 'Nordic Regional'
  },
  {
    ident: '6O',
    name: 'Orbest S.A.'
  },
  {
    ident: '6P',
    name: 'Gryphon Airlines'
  },
  {
    ident: '6Q',
    name: 'Cham Wings Airlines'
  },
  {
    ident: '6R',
    name: 'Georgian Airlines'
  },
  {
    ident: '6S',
    name: 'Kato Airlines'
  },
  {
    ident: '6T',
    name: 'Air Mandalay'
  },
  {
    ident: '6U',
    name: 'Air Ukraine'
  },
  {
    ident: '6V',
    name: 'Aviation Mars Rk'
  },
  {
    ident: '6W',
    name: 'Saratov Airlines'
  },
  {
    ident: '6X',
    name: 'Charter Services/SSCI System'
  },
  {
    ident: '6Y',
    name: 'Smartlynx Airlines Ltd.'
  },
  {
    ident: '6Z',
    name: 'Ukrainian Cargo Airways'
  },
  {
    ident: '7A',
    name: 'Air Next Co Ltd.'
  },
  {
    ident: '7B',
    name: 'Moscow Airlines'
  },
  {
    ident: '7C',
    name: 'Jeju Air Co Ltd.'
  },
  {
    ident: '7D',
    name: 'Donbassaero Airlines Llc'
  },
  {
    ident: '7E',
    name: 'Aeroline Gmbh'
  },
  {
    ident: '7F',
    name: 'First Air'
  },
  {
    ident: '7G',
    name: 'Star Flyer Inc.'
  },
  {
    ident: '7H',
    name: 'Era Aviation'
  },
  {
    ident: '7I',
    name: 'Insel Air Intl'
  },
  {
    ident: '7J',
    name: 'Tajikair'
  },
  {
    ident: '7K',
    name: 'Kogalymavia Airlines Jsc'
  },
  {
    ident: '7L',
    name: 'AB Airlines'
  },
  {
    ident: '7M',
    name: 'Mayair'
  },
  {
    ident: '7N',
    name: 'Inland Aviation Svcs Inc'
  },
  {
    ident: '7P',
    name: 'Batavia Air Pt Metro'
  },
  {
    ident: '7Q',
    name: 'Pawa Dominicana'
  },
  {
    ident: '7R',
    name: 'Rusline'
  },
  {
    ident: '7S',
    name: 'Arctic Trans Svcs'
  },
  {
    ident: '7T',
    name: 'Trenitalia S.P.A.'
  },
  {
    ident: '7U',
    name: 'Aviaenegro'
  },
  {
    ident: '7V',
    name: 'Federal Airlines Pty Ltd'
  },
  {
    ident: '7W',
    name: 'Wind Rose'
  },
  {
    ident: '7Y',
    name: 'Mid Airlines'
  },
  {
    ident: '7Z',
    name: 'Halcyonair'
  },
  {
    ident: '8A',
    name: 'Atlas Blue'
  },
  {
    ident: '8B',
    name: 'Business Air'
  },
  {
    ident: '8C',
    name: 'East Star Airlines'
  },
  {
    ident: '8D',
    name: 'Servant Air Inc.'
  },
  {
    ident: '8E',
    name: 'Bering Air Inc.'
  },
  {
    ident: '8F',
    name: 'Fischer Air'
  },
  {
    ident: '8G',
    name: 'Instone Air'
  },
  {
    ident: '8H',
    name: 'Highland Airways Limited'
  },
  {
    ident: '8I',
    name: 'Insel Air Aruba'
  },
  {
    ident: '8J',
    name: 'Jet4you'
  },
  {
    ident: '8K',
    name: 'Air Ostrava'
  },
  {
    ident: '8L',
    name: 'Lucky Air Co Ltd'
  },
  {
    ident: '8M',
    name: 'Myanmar Airways International'
  },
  {
    ident: '8N',
    name: 'Barents Airlink'
  },
  {
    ident: '8O',
    name: 'Onur Air'
  },
  {
    ident: '8P',
    name: 'Pacificastal Airlines'
  },
  {
    ident: '8Q',
    name: 'Onur Air'
  },
  {
    ident: '8R',
    name: 'Sol Lineas Aereas'
  },
  {
    ident: '8S',
    name: 'Shun Tak China Travel'
  },
  {
    ident: '8T',
    name: 'Air Tindi Ltd.'
  },
  {
    ident: '8U',
    name: 'Afriqiyah Airways'
  },
  {
    ident: '8V',
    name: 'Wright Air Service'
  },
  {
    ident: '8W',
    name: 'Private Wings Flugchart'
  },
  {
    ident: '8Y',
    name: 'Air Burundi'
  },
  {
    ident: '8Z',
    name: 'Wizz Air Bulgaria Airlines Ead'
  },
  {
    ident: '9A',
    name: 'Visa Airways Pvt Ltd.'
  },
  {
    ident: '9B',
    name: 'Deutsche Bahn'
  },
  {
    ident: '9C',
    name: 'Spring Airlines'
  },
  {
    ident: '9D',
    name: 'Toumai Air Tchad'
  },
  {
    ident: '9E',
    name: 'Pinnacle Airlines'
  },
  {
    ident: '9F',
    name: 'Eurostar (uk)'
  },
  {
    ident: '9G',
    name: 'Airport Express Rail Ltd'
  },
  {
    ident: '9H',
    name: 'Dutch Antilles Express'
  },
  {
    ident: '9I',
    name: 'Thai Sky Airlines Co Ltd'
  },
  {
    ident: '9J',
    name: 'Dana Air'
  },
  {
    ident: '9K',
    name: 'Cape Air'
  },
  {
    ident: '9L',
    name: 'Colgan Air'
  },
  {
    ident: '9M',
    name: 'Central Mountain Air'
  },
  {
    ident: '9N',
    name: 'Tropic Air'
  },
  {
    ident: '9O',
    name: 'Nacam'
  },
  {
    ident: '9P',
    name: 'Atlantic Airlines'
  },
  {
    ident: '9Q',
    name: 'Pb Air Company Ltd'
  },
  {
    ident: '9R',
    name: 'Satena'
  },
  {
    ident: '9T',
    name: 'Transwest Air'
  },
  {
    ident: '9U',
    name: 'Air Moldova'
  },
  {
    ident: '9V',
    name: 'Avior Airlines'
  },
  {
    ident: '9W',
    name: 'Jet Airways (india) Limited'
  },
  {
    ident: '9X',
    name: 'New Axis Airways'
  },
  {
    ident: '9Y',
    name: 'Fly Georgia'
  },
  {
    ident: '9Z',
    name: 'Van Galder Bus'
  },
  {
    ident: 'A1',
    name: 'G2 Switchworks Corp'
  },
  {
    ident: 'A2',
    name: 'Astra Airlines'
  },
  {
    ident: 'A3',
    name: 'Aegean Airline S.a'
  },
  {
    ident: 'A4',
    name: 'Areocon'
  },
  {
    ident: 'A5',
    name: 'Airlinair'
  },
  {
    ident: 'A6',
    name: 'Aaa - Air Alps Aviation'
  },
  {
    ident: 'A7',
    name: 'Air Plus Comet'
  },
  {
    ident: 'A8',
    name: 'Benin Golf Air'
  },
  {
    ident: 'A9',
    name: 'Georgian Airways'
  },
  {
    ident: 'AA',
    name: 'American Airlines'
  },
  {
    ident: 'AB',
    name: 'Air Berlin'
  },
  {
    ident: 'AC',
    name: 'Air Canada'
  },
  {
    ident: 'AD',
    name: 'Azul Airlines'
  },
  {
    ident: 'AE',
    name: 'Mandarin Airlines'
  },
  {
    ident: 'AF',
    name: 'Air France'
  },
  {
    ident: 'AG',
    name: 'Aruba Airlines'
  },
  {
    ident: 'AH',
    name: 'Air Algerie'
  },
  {
    ident: 'AI',
    name: 'Nacil Dba Air India'
  },
  {
    ident: 'AJ',
    name: 'Aero Contractors'
  },
  {
    ident: 'AK',
    name: 'Airasia Sdn Bhd'
  },
  {
    ident: 'AL',
    name: 'Midwest Connect'
  },
  {
    ident: 'AM',
    name: 'Aeromexico-Aerovias De Mexico S.a.'
  },
  {
    ident: 'AN',
    name: 'Ansett Australia'
  },
  {
    ident: 'AO',
    name: 'Avianova'
  },
  {
    ident: 'AP',
    name: 'Airone Spa'
  },
  {
    ident: 'AQ',
    name: 'Mapjet'
  },
  {
    ident: 'AR',
    name: 'Aerolineas Argentinas'
  },
  {
    ident: 'AS',
    name: 'Alaska Airlines'
  },
  {
    ident: 'AT',
    name: 'Royal Air Maroc'
  },
  {
    ident: 'AU',
    name: 'Austral Lineas Aereas S.a.'
  },
  {
    ident: 'AV',
    name: 'Avianca - Aerovias Delntinente Amer'
  },
  {
    ident: 'AW',
    name: 'Dirgantara Air Services'
  },
  {
    ident: 'AX',
    name: 'Trans States Airlines'
  },
  {
    ident: 'AY',
    name: 'Finnair'
  },
  {
    ident: 'AZ',
    name: 'Alitalia Cai Spa'
  },
  {
    ident: 'B1',
    name: 'Brewster Bus'
  },
  {
    ident: 'B2',
    name: 'Belavia'
  },
  {
    ident: 'B3',
    name: 'Bellview Airlines'
  },
  {
    ident: 'B4',
    name: 'Zanair Limited'
  },
  {
    ident: 'B5',
    name: 'East African Safari Air Express'
  },
  {
    ident: 'B6',
    name: 'Jetblue Airways'
  },
  {
    ident: 'B7',
    name: 'Uni Airwaysrporation'
  },
  {
    ident: 'B8',
    name: 'Eritrean Airlines'
  },
  {
    ident: 'B9',
    name: 'Iran Air Tours'
  },
  {
    ident: 'BA',
    name: 'British Airways'
  },
  {
    ident: 'BB',
    name: 'Seaborne Airlines'
  },
  {
    ident: 'BC',
    name: 'Skymark Airlines'
  },
  {
    ident: 'BD',
    name: 'BMI'
  },
  {
    ident: 'BE',
    name: 'FlyBe'
  },
  {
    ident: 'BF',
    name: 'Bluebird Cargo-290'
  },
  {
    ident: 'BG',
    name: 'Biman Bangladesh Airlines'
  },
  {
    ident: 'BH',
    name: 'Hawkair Aviation Services'
  },
  {
    ident: 'BI',
    name: 'Royal Brunei Airlines'
  },
  {
    ident: 'BJ',
    name: 'Nouvelair Tunisie'
  },
  {
    ident: 'BK',
    name: 'Okay Airways'
  },
  {
    ident: 'BL',
    name: 'Jetstar Pacific Airlines'
  },
  {
    ident: 'BM',
    name: 'British Midland Regional'
  },
  {
    ident: 'BN',
    name: 'Bahrain Air'
  },
  {
    ident: 'BO',
    name: 'BB Airways'
  },
  {
    ident: 'BP',
    name: 'Air Botswana Pty'
  },
  {
    ident: 'BQ',
    name: 'Aeromar C. Por. A'
  },
  {
    ident: 'BR',
    name: 'Eva Airways'
  },
  {
    ident: 'BS',
    name: 'British Intl'
  },
  {
    ident: 'BT',
    name: 'Air Balticrporation S'
  },
  {
    ident: 'BU',
    name: 'SAS Norway'
  },
  {
    ident: 'BV',
    name: 'Blue Panorama Airlines Spa'
  },
  {
    ident: 'BW',
    name: 'Caribbean Airlines'
  },
  {
    ident: 'BX',
    name: 'Air Busan'
  },
  {
    ident: 'BY',
    name: 'Thomsonfly Limited'
  },
  {
    ident: 'C0',
    name: 'Nowy Przewoznik'
  },
  {
    ident: 'C2',
    name: 'Air Luxor'
  },
  {
    ident: 'C3',
    name: 'Contact Air'
  },
  {
    ident: 'C5',
    name: 'Commutair'
  },
  {
    ident: 'C6',
    name: 'Canjet Airlines'
  },
  {
    ident: 'C7',
    name: 'Rico Linhas Aereas Rla'
  },
  {
    ident: 'C8',
    name: 'North American Charters'
  },
  {
    ident: 'C9',
    name: 'Cirrus Luftfarhrtgesellschft Mbh'
  },
  {
    ident: 'CA',
    name: 'Air China Limited'
  },
  {
    ident: 'CB',
    name: 'Scot Airways'
  },
  {
    ident: 'CC',
    name: 'Macair Airlines'
  },
  {
    ident: 'CD',
    name: 'Corendon Dutch Airlines'
  },
  {
    ident: 'CE',
    name: 'Chalair Aviation'
  },
  {
    ident: 'CF',
    name: 'City Airline Ab'
  },
  {
    ident: 'CG',
    name: 'Airlines Of Papua New Guinea'
  },
  {
    ident: 'CH',
    name: 'Bemidji Aviation Services'
  },
  {
    ident: 'CI',
    name: 'China Airlines'
  },
  {
    ident: 'CJ',
    name: 'BA City Flyer'
  },
  {
    ident: 'CK',
    name: 'Andesmar Lineas'
  },
  {
    ident: 'CL',
    name: 'Lufthansa Cityline'
  },
  {
    ident: 'CM',
    name: 'Copa Airlines'
  },
  {
    ident: 'CN',
    name: 'Grand China Air'
  },
  {
    ident: 'CO',
    name: 'Continental Airlines'
  },
  {
    ident: 'CP',
    name: 'Compass'
  },
  {
    ident: 'CQ',
    name: 'Czech Connect Airlines'
  },
  {
    ident: 'CR',
    name: 'Oag Worldwide'
  },
  {
    ident: 'CS',
    name: 'Continental Micronesia'
  },
  {
    ident: 'CT',
    name: 'Alitalia City Liner Spa'
  },
  {
    ident: 'CU',
    name: 'Cubana De Aviacion'
  },
  {
    ident: 'CV',
    name: 'Air Chathams'
  },
  {
    ident: 'CW',
    name: 'Air Marshall Island'
  },
  {
    ident: 'CX',
    name: 'Cathay Pacific Airways'
  },
  {
    ident: 'CY',
    name: 'Cyprus Airways'
  },
  {
    ident: 'CZ',
    name: 'China Southern Airlines'
  },
  {
    ident: 'D0',
    name: 'So. Australia Air'
  },
  {
    ident: 'D1',
    name: 'Alidaunia'
  },
  {
    ident: 'D2',
    name: 'Severstal Joint Stock Co'
  },
  {
    ident: 'D3',
    name: 'Daallo Airlines'
  },
  {
    ident: 'D4',
    name: 'Alidaunia'
  },
  {
    ident: 'D5',
    name: 'Dau Air Verwaltungs Ag'
  },
  {
    ident: 'D6',
    name: 'Inter-Aviation Services'
  },
  {
    ident: 'D7',
    name: 'Airasia X Sdn. Bhd'
  },
  {
    ident: 'D8',
    name: 'Norwegian Air Intl'
  },
  {
    ident: 'D9',
    name: 'Jsc Donavia'
  },
  {
    ident: 'DA',
    name: 'Air Georgia'
  },
  {
    ident: 'DB',
    name: 'Brit Air'
  },
  {
    ident: 'DC',
    name: 'Golden Air Flyg Ab'
  },
  {
    ident: 'DD',
    name: 'Nok Air'
  },
  {
    ident: 'DE',
    name: 'Condor Flugdienst'
  },
  {
    ident: 'DF',
    name: 'Condor Berlin'
  },
  {
    ident: 'DG',
    name: 'South East Asian Air'
  },
  {
    ident: 'DH',
    name: 'Independence Air'
  },
  {
    ident: 'DI',
    name: 'Marabu'
  },
  {
    ident: 'DJ',
    name: 'Virgin Australia Airlines'
  },
  {
    ident: 'DK',
    name: 'Eastland Air'
  },
  {
    ident: 'DL',
    name: 'Delta Air Lines'
  },
  {
    ident: 'DM',
    name: 'Sterling Blue'
  },
  {
    ident: 'DN',
    name: 'Senegal Airlines'
  },
  {
    ident: 'DO',
    name: 'Air Vallee'
  },
  {
    ident: 'DP',
    name: 'First Choice Airways'
  },
  {
    ident: 'DQ',
    name: 'Coastal Air Transport'
  },
  {
    ident: 'DR',
    name: 'Air Link Pty. Ltd'
  },
  {
    ident: 'DS',
    name: 'Easyjet Switzerland S.a.'
  },
  {
    ident: 'DT',
    name: 'Taag-Linhas Aereas De Angola'
  },
  {
    ident: 'DU',
    name: 'Hemus Air'
  },
  {
    ident: 'DV',
    name: 'Pll Scat Aircompany'
  },
  {
    ident: 'DW',
    name: 'Aero-Charter'
  },
  {
    ident: 'DX',
    name: 'Danish Air Transport'
  },
  {
    ident: 'DY',
    name: 'Norwegian Air Shuttle A.s.'
  },
  {
    ident: 'DZ',
    name: 'Transcaraibes Air Intl'
  },
  {
    ident: 'E0',
    name: 'Eos Airlines'
  },
  {
    ident: 'E2',
    name: 'Eurowings Europe'
  },
  {
    ident: 'E3',
    name: 'Eagles Spa'
  },
  {
    ident: 'E4',
    name: 'Estelar Latinoamericana'
  },
  {
    ident: 'E5',
    name: 'Air Arabia Egypt'
  },
  {
    ident: 'E6',
    name: 'Eurowings Europe'
  },
  {
    ident: 'E7',
    name: 'Fly European'
  },
  {
    ident: 'E8',
    name: 'Jsc Semeyavia'
  },
  {
    ident: 'E9',
    name: 'Aircuenca S A'
  },
  {
    ident: 'EA',
    name: 'European Air Express'
  },
  {
    ident: 'EB',
    name: 'Pullmantur Air'
  },
  {
    ident: 'EC',
    name: 'Openskies'
  },
  {
    ident: 'ED',
    name: 'Airblue'
  },
  {
    ident: 'EE',
    name: 'Eastern Skyjets'
  },
  {
    ident: 'EF',
    name: 'Far Eastern Air Transport'
  },
  {
    ident: 'EG',
    name: 'Cjsc East Air'
  },
  {
    ident: 'EH',
    name: 'Ana Wings'
  },
  {
    ident: 'EI',
    name: 'Aer Lingus Limited'
  },
  {
    ident: 'EJ',
    name: 'New England Airlines'
  },
  {
    ident: 'EK',
    name: 'Emirates'
  },
  {
    ident: 'EL',
    name: 'Ellinair'
  },
  {
    ident: 'EM',
    name: 'Aero Benin Sa'
  },
  {
    ident: 'EN',
    name: 'Air Dolomiti S P A'
  },
  {
    ident: 'EO',
    name: 'Hewa Bora Airways'
  },
  {
    ident: 'EP',
    name: 'Iran Aseman Airlines'
  },
  {
    ident: 'EQ',
    name: 'Tame Linea Aerea Del Ecuador'
  },
  {
    ident: 'ET',
    name: 'Ethiopian Airlines Enterprise'
  },
  {
    ident: 'EU',
    name: 'Chengdu Airlines'
  },
  {
    ident: 'EV',
    name: 'Atlantic Southeast Airlines'
  },
  {
    ident: 'EW',
    name: 'Eurowings'
  },
  {
    ident: 'EX',
    name: 'Aerolinas Santo Domingo'
  },
  {
    ident: 'EY',
    name: 'Etihad Airways'
  },
  {
    ident: 'EZ',
    name: 'Sun Air Of Scandinavia A'
  },
  {
    ident: 'F0',
    name: 'Fset Partition 2p'
  },
  {
    ident: 'F1',
    name: 'Farelogix'
  },
  {
    ident: 'F2',
    name: 'Fly Hava Yollari A.s.'
  },
  {
    ident: 'F4',
    name: 'Albarka Air Services'
  },
  {
    ident: 'F5',
    name: 'Cosmic Air Pvt Ltd'
  },
  {
    ident: 'F6',
    name: 'First Cambodia Airlines'
  },
  {
    ident: 'F7',
    name: 'Etihad Regional'
  },
  {
    ident: 'F8',
    name: 'Freedom Airlines'
  },
  {
    ident: 'F9',
    name: 'Frontier Airlines'
  },
  {
    ident: 'FA',
    name: 'Airlink Airways Limited'
  },
  {
    ident: 'FB',
    name: 'Bulgaria Air'
  },
  {
    ident: 'FC',
    name: 'Finnishmmuter Airlines'
  },
  {
    ident: 'FD',
    name: 'Thai Airasia'
  },
  {
    ident: 'FE',
    name: 'Far Eastern Air Trans'
  },
  {
    ident: 'FF',
    name: 'Airshop B.v.'
  },
  {
    ident: 'FG',
    name: 'Ariana Afghan Airlines'
  },
  {
    ident: 'FH',
    name: 'Freebird Airlines'
  },
  {
    ident: 'FI',
    name: 'Icelandair'
  },
  {
    ident: 'FJ',
    name: 'Air Pacific Limited'
  },
  {
    ident: 'FK',
    name: 'Keewatin Air'
  },
  {
    ident: 'FL',
    name: 'Airtran Airways'
  },
  {
    ident: 'FM',
    name: 'Shanghai Airlines'
  },
  {
    ident: 'FN',
    name: 'Regional Air Lines'
  },
  {
    ident: 'FO',
    name: 'Felix Airways'
  },
  {
    ident: 'FP',
    name: 'Freedom Air'
  },
  {
    ident: 'FQ',
    name: 'Brindabella'
  },
  {
    ident: 'FR',
    name: 'Ryanair'
  },
  {
    ident: 'FS',
    name: 'Syphax Airlines S A'
  },
  {
    ident: 'FT',
    name: 'Siem Reap Airways International'
  },
  {
    ident: 'FU',
    name: 'Futura International Airways'
  },
  {
    ident: 'FV',
    name: 'Rossiya Airlines'
  },
  {
    ident: 'FW',
    name: 'Ibex Airlines Co. Ltd'
  },
  {
    ident: 'FX',
    name: 'Fedex Airlines'
  },
  {
    ident: 'FY',
    name: 'Firefly'
  },
  {
    ident: 'FZ',
    name: 'Flydubai'
  },
  {
    ident: 'G0',
    name: 'Ghana International Airlines'
  },
  {
    ident: 'G1',
    name: 'Trans-Air-Link'
  },
  {
    ident: 'G2',
    name: 'Avirex Gabon'
  },
  {
    ident: 'G3',
    name: 'Gol'
  },
  {
    ident: 'G4',
    name: 'Allegiant Air Llc'
  },
  {
    ident: 'G6',
    name: 'Angkor Airways'
  },
  {
    ident: 'G7',
    name: 'Gojet Airlines'
  },
  {
    ident: 'G8',
    name: 'Joint Stock Co Enkor'
  },
  {
    ident: 'G9',
    name: 'Air Arabia'
  },
  {
    ident: 'GA',
    name: 'Garuda Indonesia'
  },
  {
    ident: 'GC',
    name: 'Gambia Intl Airlines'
  },
  {
    ident: 'GD',
    name: 'Air Alpha Greenland'
  },
  {
    ident: 'GE',
    name: 'Transasia Airwaysrporation'
  },
  {
    ident: 'GF',
    name: 'Gulf Air'
  },
  {
    ident: 'GG',
    name: 'Air Comores Intl'
  },
  {
    ident: 'GH',
    name: 'Globus'
  },
  {
    ident: 'GI',
    name: 'Air Company Itek-Air Ltd'
  },
  {
    ident: 'GJ',
    name: 'Eurofly'
  },
  {
    ident: 'GK',
    name: 'Jetstar Japan Co Ltd'
  },
  {
    ident: 'GL',
    name: 'Air Greenland'
  },
  {
    ident: 'GM',
    name: 'Germania Flug AG'
  },
  {
    ident: 'GN',
    name: 'Air Gabon'
  },
  {
    ident: 'GO',
    name: 'Go Fly Ltd'
  },
  {
    ident: 'GP',
    name: 'Pantanal Linhas'
  },
  {
    ident: 'GQ',
    name: 'Sky Express S.a.'
  },
  {
    ident: 'GR',
    name: 'Aurigny Air Services'
  },
  {
    ident: 'GS',
    name: 'Tianjin Airlines'
  },
  {
    ident: 'GT',
    name: 'GB Airways'
  },
  {
    ident: 'GU',
    name: 'Aviateca S.a.'
  },
  {
    ident: 'GV',
    name: 'Aero Flight'
  },
  {
    ident: 'GW',
    name: 'Air Lines Of Kuban'
  },
  {
    ident: 'GY',
    name: 'Gabon Airlines'
  },
  {
    ident: 'GZ',
    name: 'Air Rarotonga'
  },
  {
    ident: 'H1',
    name: 'Hahn Air Systems'
  },
  {
    ident: 'H2',
    name: 'Sky Airline'
  },
  {
    ident: 'H3',
    name: 'Hermes Airlines S.a'
  },
  {
    ident: 'H4',
    name: 'Heli Securite Helicopter'
  },
  {
    ident: 'H5',
    name: 'Magadan Airlines'
  },
  {
    ident: 'H6',
    name: 'Hageland Aviation / DBA Era Alaska'
  },
  {
    ident: 'H7',
    name: 'Eaglr Air Ltd'
  },
  {
    ident: 'H8',
    name: 'Dalavia Far East Airways'
  },
  {
    ident: 'H9',
    name: 'Pegasus Hava Tasimacilig'
  },
  {
    ident: 'HA',
    name: 'Hawaiian Airlines'
  },
  {
    ident: 'HB',
    name: 'Homer Air Inc'
  },
  {
    ident: 'HC',
    name: 'Lip Air Ltd'
  },
  {
    ident: 'HD',
    name: 'Airdo Co Ltd'
  },
  {
    ident: 'HE',
    name: 'Lgw - Luftfahrtgesellschaft Walter'
  },
  {
    ident: 'HF',
    name: 'Tuifly'
  },
  {
    ident: 'HG',
    name: 'Niki Luftfahrt'
  },
  {
    ident: 'HH',
    name: 'Islandsflug Hf'
  },
  {
    ident: 'HI',
    name: 'Papillon Airways'
  },
  {
    ident: 'HJ',
    name: 'Hellas Jet Sa'
  },
  {
    ident: 'HK',
    name: 'Hamburg Airways'
  },
  {
    ident: 'HM',
    name: 'Air Seychelles'
  },
  {
    ident: 'HN',
    name: 'Heavylift Cargo Airlines Pty Limited'
  },
  {
    ident: 'HO',
    name: 'Juneyao Airlines'
  },
  {
    ident: 'HP',
    name: 'America West'
  },
  {
    ident: 'HQ',
    name: 'Harmony Airways'
  },
  {
    ident: 'HR',
    name: 'Hahn Air Lines'
  },
  {
    ident: 'HS',
    name: 'Svenska Direktflyg Ab'
  },
  {
    ident: 'HT',
    name: 'Hellenic Imperial Airway'
  },
  {
    ident: 'HU',
    name: 'Hainan Airlines'
  },
  {
    ident: 'HV',
    name: 'Transavia Airlines'
  },
  {
    ident: 'HW',
    name: 'North-Wright Airways'
  },
  {
    ident: 'HX',
    name: 'Hong Kong Airlines'
  },
  {
    ident: 'HY',
    name: 'Uzbekistan Airways'
  },
  {
    ident: 'HZ',
    name: 'Sakhalinskie Aviatrassy'
  },
  {
    ident: 'I0',
    name: 'Sabre Imps Air'
  },
  {
    ident: 'I1',
    name: 'Eyeone'
  },
  {
    ident: 'I2',
    name: 'Iberia Express'
  },
  {
    ident: 'I3',
    name: 'Ivoirienne De Transport'
  },
  {
    ident: 'I4',
    name: 'Rutair Ltd Dba Airlink'
  },
  {
    ident: 'I5',
    name: 'Air Mali'
  },
  {
    ident: 'I6',
    name: 'Mexicanalink'
  },
  {
    ident: 'I7',
    name: 'Paramount Airways'
  },
  {
    ident: 'I8',
    name: 'Izhavia Psc'
  },
  {
    ident: 'I9',
    name: 'Air Italy'
  },
  {
    ident: 'IA',
    name: 'Iraqi Airways'
  },
  {
    ident: 'IB',
    name: 'Iberia'
  },
  {
    ident: 'IC',
    name: 'Nacil Dba Indian Airlines'
  },
  {
    ident: 'ID',
    name: 'Batik Air Indonesia'
  },
  {
    ident: 'IE',
    name: 'Solomon Airlines'
  },
  {
    ident: 'IF',
    name: 'Islas Airways'
  },
  {
    ident: 'IG',
    name: 'Meridiana Fly S.p.a.'
  },
  {
    ident: 'IH',
    name: 'Irtysh-Air'
  },
  {
    ident: 'II',
    name: 'Business Air'
  },
  {
    ident: 'IK',
    name: 'Imair'
  },
  {
    ident: 'IL',
    name: 'Istanbul Airlines'
  },
  {
    ident: 'IM',
    name: 'Menajet'
  },
  {
    ident: 'IN',
    name: 'Mat Macedonian Airlines'
  },
  {
    ident: 'IO',
    name: 'Indonesian  airlines'
  },
  {
    ident: 'IP',
    name: 'Atyrau Aue Joly'
  },
  {
    ident: 'IQ',
    name: 'Augsburg Airways'
  },
  {
    ident: 'IR',
    name: 'Iran Air'
  },
  {
    ident: 'IS',
    name: 'Island Airlines'
  },
  {
    ident: 'IT',
    name: 'Kingfisher Airlines'
  },
  {
    ident: 'IU',
    name: 'Hevi Lift Png Pty Ltd'
  },
  {
    ident: 'IV',
    name: 'Wind Jet Spa'
  },
  {
    ident: 'IW',
    name: 'Wings Air'
  },
  {
    ident: 'IX',
    name: 'Air India Express'
  },
  {
    ident: 'IY',
    name: 'Yemenia Yemen Airways'
  },
  {
    ident: 'IZ',
    name: 'Arkia-Israeli Airlines'
  },
  {
    ident: 'J0',
    name: 'Jet Link Express'
  },
  {
    ident: 'J1',
    name: 'Primair'
  },
  {
    ident: 'J2',
    name: 'Azerbaijan Hava Yollary'
  },
  {
    ident: 'J3',
    name: 'Northwestern Air Lease'
  },
  {
    ident: 'J4',
    name: 'Wondair Aviation S.l'
  },
  {
    ident: 'J5',
    name: 'Alaska Seaplane Service'
  },
  {
    ident: 'J7',
    name: 'Jmc Airlines Ltd'
  },
  {
    ident: 'J8',
    name: 'Berjaya Air Sdn. Bhd.'
  },
  {
    ident: 'J9',
    name: 'Jazeera Airways'
  },
  {
    ident: 'JA',
    name: 'Bh Airlines'
  },
  {
    ident: 'JB',
    name: 'Avia Express Sweden Ab'
  },
  {
    ident: 'JC',
    name: 'Jal Express'
  },
  {
    ident: 'JD',
    name: 'Deer Jet'
  },
  {
    ident: 'JE',
    name: 'Mango'
  },
  {
    ident: 'JF',
    name: 'L.a.b. Flying Service'
  },
  {
    ident: 'JG',
    name: 'Air Greece-Aerodromisis'
  },
  {
    ident: 'JH',
    name: 'Fuji Dream Airlines'
  },
  {
    ident: 'JI',
    name: 'Eastern Caribbean Air'
  },
  {
    ident: 'JJ',
    name: 'Tam - Linhas Aereas'
  },
  {
    ident: 'JK',
    name: 'Spanair S.A.'
  },
  {
    ident: 'JL',
    name: 'Japan Airlines International'
  },
  {
    ident: 'JM',
    name: 'Air Jamaica'
  },
  {
    ident: 'JN',
    name: 'Joon'
  },
  {
    ident: 'JO',
    name: 'Jalways'
  },
  {
    ident: 'JP',
    name: 'Adria Airways - The Airline Of Slovenia'
  },
  {
    ident: 'JQ',
    name: 'Jetstar Airways Pty Limited'
  },
  {
    ident: 'JR',
    name: 'Joy Air'
  },
  {
    ident: 'JS',
    name: 'Air Koryo'
  },
  {
    ident: 'JT',
    name: 'Lion Airlines'
  },
  {
    ident: 'JU',
    name: 'Air Serbia'
  },
  {
    ident: 'JV',
    name: 'Bearskin Lake Air Services'
  },
  {
    ident: 'JW',
    name: 'Jamaica Far East Limited'
  },
  {
    ident: 'JX',
    name: 'Nice Helicopters'
  },
  {
    ident: 'JY',
    name: 'Air Turks And Caicos'
  },
  {
    ident: 'JZ',
    name: 'Skyways Ab'
  },
  {
    ident: 'K.',
    name: 'Skyteam'
  },
  {
    ident: 'K0',
    name: 'Worldwide Air'
  },
  {
    ident: 'K1',
    name: 'Topas Co. Ltd'
  },
  {
    ident: 'K2',
    name: 'Eurolot S.a.'
  },
  {
    ident: 'K3',
    name: 'Taquan Air Service'
  },
  {
    ident: 'K4',
    name: 'Kron Flyg'
  },
  {
    ident: 'K5',
    name: 'Seaport Airlines'
  },
  {
    ident: 'K6',
    name: 'Cambodia Angkor Air'
  },
  {
    ident: 'K7',
    name: 'Air Kbz Ltd'
  },
  {
    ident: 'K8',
    name: 'Zambia Skyways Ltd'
  },
  {
    ident: 'K9',
    name: 'Skyward Aviation'
  },
  {
    ident: 'KA',
    name: 'Hong Kong Dragon Airlines Limited'
  },
  {
    ident: 'KB',
    name: 'Druk Airrporation'
  },
  {
    ident: 'KC',
    name: 'Air Astana'
  },
  {
    ident: 'KD',
    name: 'Kalstar Aviation'
  },
  {
    ident: 'KE',
    name: 'Korean Air Lines Ltd.'
  },
  {
    ident: 'KF',
    name: 'Blue1 Oy'
  },
  {
    ident: 'KG',
    name: 'Aerogaviota S.a.'
  },
  {
    ident: 'KH',
    name: 'Kyrgyz Airways'
  },
  {
    ident: 'KI',
    name: 'Adam Skyconnection Air'
  },
  {
    ident: 'KJ',
    name: 'Asian Air'
  },
  {
    ident: 'KK',
    name: 'Atlasjet Airlines'
  },
  {
    ident: 'KL',
    name: 'KLM Royal Dutch Airlines'
  },
  {
    ident: 'KM',
    name: 'Air Malta P.l.c.'
  },
  {
    ident: 'KN',
    name: 'China United Airlines'
  },
  {
    ident: 'KO',
    name: 'Air Company Khors'
  },
  {
    ident: 'KP',
    name: 'Asky'
  },
  {
    ident: 'KQ',
    name: 'Kenya Airways'
  },
  {
    ident: 'KR',
    name: 'Air Bishkek Aircompany'
  },
  {
    ident: 'KS',
    name: 'Penair'
  },
  {
    ident: 'KT',
    name: 'Kyrgyz Air'
  },
  {
    ident: 'KU',
    name: 'Kuwait Airways'
  },
  {
    ident: 'KV',
    name: 'Kavminvodyavia'
  },
  {
    ident: 'KX',
    name: 'Cayman Airways'
  },
  {
    ident: 'KY',
    name: 'Kunming Airlines'
  },
  {
    ident: 'KZ',
    name: 'Nippon Cargo Airlines'
  },
  {
    ident: 'L2',
    name: 'Djibouti Airlines'
  },
  {
    ident: 'L3',
    name: 'Lynx Aviation'
  },
  {
    ident: 'L4',
    name: 'Air Liaison'
  },
  {
    ident: 'L5',
    name: 'Lufttransport As'
  },
  {
    ident: 'L6',
    name: 'Mauritanian Airlines Int'
  },
  {
    ident: 'L7',
    name: 'Laoag Intl'
  },
  {
    ident: 'L8',
    name: 'Air Luxor Gb, Lda'
  },
  {
    ident: 'L9',
    name: 'Belle Air Europe'
  },
  {
    ident: 'LA',
    name: 'LAN Airlines S.A.'
  },
  {
    ident: 'LB',
    name: 'Lloyd Aereo Boliviano S A'
  },
  {
    ident: 'LC',
    name: 'Varig Logistica Sa'
  },
  {
    ident: 'LD',
    name: 'Linea Turistica Aereotuy'
  },
  {
    ident: 'LE',
    name: 'Helgoland Airlines'
  },
  {
    ident: 'LF',
    name: 'Nordic East Airlink'
  },
  {
    ident: 'LG',
    name: 'Luxair'
  },
  {
    ident: 'LH',
    name: 'Lufthansa'
  },
  {
    ident: 'LI',
    name: 'Liat (1974)'
  },
  {
    ident: 'LJ',
    name: 'Jin Air Ltd'
  },
  {
    ident: 'LK',
    name: 'Air Luxor S.A.'
  },
  {
    ident: 'LL',
    name: 'Miami Air'
  },
  {
    ident: 'LM',
    name: 'Livingston'
  },
  {
    ident: 'LN',
    name: 'Libyan Arab Airlines'
  },
  {
    ident: 'LO',
    name: 'LOT Polish Airlines'
  },
  {
    ident: 'LP',
    name: 'LAN Peru S.A.'
  },
  {
    ident: 'LQ',
    name: 'Lebanese Air Transport'
  },
  {
    ident: 'LR',
    name: 'Lineas Aereasstarricenses S.a. (lacsa)'
  },
  {
    ident: 'LS',
    name: 'Jet2.com Limited'
  },
  {
    ident: 'LT',
    name: 'LTU International Airways'
  },
  {
    ident: 'LU',
    name: 'Transporte Aereo Sa'
  },
  {
    ident: 'LV',
    name: 'Albanian Airlines Mak S.h.p.k'
  },
  {
    ident: 'LW',
    name: 'Pacific Wings L.l.c'
  },
  {
    ident: 'LX',
    name: 'Swiss International Airlines'
  },
  {
    ident: 'LY',
    name: 'El Al Israel Airlines'
  },
  {
    ident: 'LZ',
    name: 'Belle Airmpany'
  },
  {
    ident: 'M0',
    name: 'Aero Mongolia'
  },
  {
    ident: 'M1',
    name: 'Markair Express'
  },
  {
    ident: 'M2',
    name: 'Mahfooz Aviation'
  },
  {
    ident: 'M3',
    name: 'North Flying As'
  },
  {
    ident: 'M4',
    name: 'Avioimpex Ad.po'
  },
  {
    ident: 'M5',
    name: 'Kenmore Air'
  },
  {
    ident: 'M6',
    name: 'Chalair'
  },
  {
    ident: 'M7',
    name: 'Marsland Aviation'
  },
  {
    ident: 'M8',
    name: 'Mekong Airlines Co Ltd'
  },
  {
    ident: 'M9',
    name: 'Motor-Sich Jsc'
  },
  {
    ident: 'MA',
    name: 'Malev Hungarian Airlines Public'
  },
  {
    ident: 'MC',
    name: 'Air Mobility Command'
  },
  {
    ident: 'MD',
    name: 'Air Madagascar'
  },
  {
    ident: 'ME',
    name: 'Middle East Airlines Airliban'
  },
  {
    ident: 'MF',
    name: 'Xiamen Airlines'
  },
  {
    ident: 'MG',
    name: 'Champion Air'
  },
  {
    ident: 'MH',
    name: 'Malaysia Airline System Berhad'
  },
  {
    ident: 'MI',
    name: 'Silkair (singapore) Pty'
  },
  {
    ident: 'MJ',
    name: 'Mihin Lanka'
  },
  {
    ident: 'MK',
    name: 'Air Mauritius'
  },
  {
    ident: 'ML',
    name: 'Air Mediterranee'
  },
  {
    ident: 'MM',
    name: 'Sociedad Aeronautica De Medellin S.a.'
  },
  {
    ident: 'MN',
    name: 'Comair'
  },
  {
    ident: 'MO',
    name: 'Calm Air International'
  },
  {
    ident: 'MP',
    name: 'Martinair Holland'
  },
  {
    ident: 'MQ',
    name: 'American Eagle Airlines'
  },
  {
    ident: 'MR',
    name: 'Air Mauritanie'
  },
  {
    ident: 'MS',
    name: 'Egyptair'
  },
  {
    ident: 'MT',
    name: 'Thomas Cook Airlines Ltd.'
  },
  {
    ident: 'MU',
    name: 'China Eastern Airlines'
  },
  {
    ident: 'MV',
    name: 'Armenian Intl Airways'
  },
  {
    ident: 'MW',
    name: 'Mokulele Airlines'
  },
  {
    ident: 'MX',
    name: 'Mexicana De Aviacion'
  },
  {
    ident: 'MY',
    name: 'Maya Island Air'
  },
  {
    ident: 'MZ',
    name: 'Merpati'
  },
  {
    ident: 'N2',
    name: 'Daghestan Airlines'
  },
  {
    ident: 'N3',
    name: 'Omskavia'
  },
  {
    ident: 'N5',
    name: 'Norfolk Air'
  },
  {
    ident: 'N6',
    name: 'Nuevo Continente S.a.'
  },
  {
    ident: 'N7',
    name: 'Nht Linhas Aereasa'
  },
  {
    ident: 'N8',
    name: 'Cr Airways'
  },
  {
    ident: 'N9',
    name: 'Niger Air Continental'
  },
  {
    ident: 'NA',
    name: 'North American Airlines'
  },
  {
    ident: 'NB',
    name: 'Sterling'
  },
  {
    ident: 'NC',
    name: 'National Jet System'
  },
  {
    ident: 'ND',
    name: 'Airlink Limited'
  },
  {
    ident: 'NE',
    name: 'Skyeurope Airlines'
  },
  {
    ident: 'NF',
    name: 'Air Vanuatu (operations) Limited'
  },
  {
    ident: 'NG',
    name: 'Lauda Air Luftfahrt Ag'
  },
  {
    ident: 'NH',
    name: 'All Nippon Airways Ltd'
  },
  {
    ident: 'NI',
    name: 'Portugalia Airlines'
  },
  {
    ident: 'NK',
    name: 'Spirit Airlines'
  },
  {
    ident: 'NL',
    name: 'Shaheen Air Intl'
  },
  {
    ident: 'NM',
    name: 'Manx2'
  },
  {
    ident: 'NN',
    name: 'Vim Airlines'
  },
  {
    ident: false,
    name: 'Neos S.p.a.'
  },
  {
    ident: 'NP',
    name: 'Nile Air'
  },
  {
    ident: 'NQ',
    name: 'Air Japan Company Ltd'
  },
  {
    ident: 'NR',
    name: 'Max Air Limited'
  },
  {
    ident: 'NS',
    name: 'Caucasus Airlines'
  },
  {
    ident: 'NT',
    name: 'Binter Canarias'
  },
  {
    ident: 'NU',
    name: 'Japan Transocean Air'
  },
  {
    ident: 'NV',
    name: 'Air Central Co., Ltd'
  },
  {
    ident: 'NW',
    name: 'Northwest Airlines'
  },
  {
    ident: 'NX',
    name: 'Air Macaumpany Limited'
  },
  {
    ident: 'NY',
    name: 'Air Iceland'
  },
  {
    ident: 'NZ',
    name: 'Air New Zealand'
  },
  {
    ident: 'O.',
    name: 'Oneworld Alliance'
  },
  {
    ident: 'O0',
    name: 'Sabre Business Aviator'
  },
  {
    ident: 'O1',
    name: 'Conoco'
  },
  {
    ident: 'O2',
    name: 'Jet Air'
  },
  {
    ident: 'O3',
    name: 'Limited Brands'
  },
  {
    ident: 'O4',
    name: 'Antrak Air Limited'
  },
  {
    ident: 'O5',
    name: 'Comores Aviation'
  },
  {
    ident: 'O6',
    name: 'Oceanair Linhas Aereas'
  },
  {
    ident: 'O7',
    name: 'K.a. Airlines'
  },
  {
    ident: 'O8',
    name: 'Axis Lines'
  },
  {
    ident: 'O9',
    name: 'Nova Airlines'
  },
  {
    ident: 'OA',
    name: 'Olympic Air'
  },
  {
    ident: 'OB',
    name: 'Boa'
  },
  {
    ident: 'OC',
    name: 'Oriental Air'
  },
  {
    ident: 'OD',
    name: 'Batik Air Malaysia'
  },
  {
    ident: 'OF',
    name: 'Air Finland Ltd'
  },
  {
    ident: 'OG',
    name: 'Fly PLAY'
  },
  {
    ident: 'OH',
    name: 'Comair'
  },
  {
    ident: 'OI',
    name: 'Prestige Airways'
  },
  {
    ident: 'OJ',
    name: 'Overland Airways Ltd'
  },
  {
    ident: 'OK',
    name: 'Czech Airlines'
  },
  {
    ident: 'OL',
    name: 'Olt-Osfriesische Lufttransport'
  },
  {
    ident: 'OM',
    name: 'Miat - Mongolian Airlines'
  },
  {
    ident: true,
    name: 'Our Airline'
  },
  {
    ident: 'OO',
    name: 'Skywest Airlines'
  },
  {
    ident: 'OP',
    name: 'Olympic Airlines'
  },
  {
    ident: 'OQ',
    name: 'Chongqing Airlines'
  },
  {
    ident: 'OR',
    name: 'Arkefly'
  },
  {
    ident: 'OS',
    name: 'Austrian'
  },
  {
    ident: 'OT',
    name: 'Aeropelican Air Services Pty'
  },
  {
    ident: 'OU',
    name: 'Croatia Airlines'
  },
  {
    ident: 'OV',
    name: 'Estonian Air'
  },
  {
    ident: 'OW',
    name: 'American Eagle'
  },
  {
    ident: 'OX',
    name: 'Orient Thai Airlines'
  },
  {
    ident: 'OY',
    name: 'Andes Lineas Aereas S.a.'
  },
  {
    ident: 'OZ',
    name: 'Asiana Airlines'
  },
  {
    ident: 'P0',
    name: 'Proflight Commuter'
  },
  {
    ident: 'P1',
    name: 'Public Charters'
  },
  {
    ident: 'P2',
    name: 'Air Kenya Express'
  },
  {
    ident: 'P3',
    name: 'Passaredo Linhas Aereas'
  },
  {
    ident: 'P4',
    name: 'Aero Lineas Sosa'
  },
  {
    ident: 'P5',
    name: 'Aero Republica S.a.'
  },
  {
    ident: 'P6',
    name: 'Air Expresso'
  },
  {
    ident: 'P7',
    name: 'East Line Airlines'
  },
  {
    ident: 'P8',
    name: 'Air Mekong'
  },
  {
    ident: 'P9',
    name: 'Peruvian Airlines'
  },
  {
    ident: 'PA',
    name: 'M'
  },
  {
    ident: 'PB',
    name: 'Provincial Airlines'
  },
  {
    ident: 'PC',
    name: 'Pegasus Airlines'
  },
  {
    ident: 'PD',
    name: 'Porter Airlines'
  },
  {
    ident: 'PE',
    name: 'Peoples Viennaline'
  },
  {
    ident: 'PF',
    name: 'Primera Scandinavia A'
  },
  {
    ident: 'PG',
    name: 'Bangkok Airways'
  },
  {
    ident: 'PH',
    name: 'Transavia Denmark'
  },
  {
    ident: 'PI',
    name: 'Sol Paraguay'
  },
  {
    ident: 'PJ',
    name: 'Air Saint Pierre'
  },
  {
    ident: 'PK',
    name: 'Pakistan Intl Airlines'
  },
  {
    ident: 'PL',
    name: 'Southern Air Charter'
  },
  {
    ident: 'PM',
    name: 'Tropic Air Limited'
  },
  {
    ident: 'PN',
    name: 'China West Air'
  },
  {
    ident: 'PO',
    name: 'Polar Air Cargo'
  },
  {
    ident: 'PP',
    name: 'Jet Aviation Business'
  },
  {
    ident: 'PQ',
    name: 'Airasia Inc.'
  },
  {
    ident: 'PR',
    name: 'Philippine Airlines'
  },
  {
    ident: 'PS',
    name: 'Ukraine International Airlines'
  },
  {
    ident: 'PT',
    name: 'West Air Sweden'
  },
  {
    ident: 'PU',
    name: 'Pluna Lineas Aereas Uruguayas S.a.'
  },
  {
    ident: 'PV',
    name: 'Saint Barth Commuter'
  },
  {
    ident: 'PW',
    name: 'Precision Air Services'
  },
  {
    ident: 'PX',
    name: 'Air Niugini'
  },
  {
    ident: 'PY',
    name: 'Surinam Airways'
  },
  {
    ident: 'PZ',
    name: 'Tam-Transportes Aereos Del Mercosur S.a.'
  },
  {
    ident: 'Q0',
    name: 'Johnson Controls'
  },
  {
    ident: 'Q2',
    name: 'Island Aviation'
  },
  {
    ident: 'Q3',
    name: 'Zambian Airways'
  },
  {
    ident: 'Q4',
    name: 'Amiri Flight'
  },
  {
    ident: 'Q5',
    name: 'Forty Mile Air'
  },
  {
    ident: 'Q6',
    name: 'Skytrans'
  },
  {
    ident: 'Q7',
    name: 'Sobelair'
  },
  {
    ident: 'Q8',
    name: 'Trans Air Congo'
  },
  {
    ident: 'Q9',
    name: 'Air Liaison'
  },
  {
    ident: 'QA',
    name: 'Aerovias Caribe Sa De Cv'
  },
  {
    ident: 'QB',
    name: 'Sky Georgia'
  },
  {
    ident: 'QC',
    name: 'Camair-Co'
  },
  {
    ident: 'QD',
    name: 'Air Class Lineas'
  },
  {
    ident: 'QE',
    name: 'Europentinental Airways'
  },
  {
    ident: 'QF',
    name: 'Qantas Airways'
  },
  {
    ident: 'QG',
    name: 'Citilink'
  },
  {
    ident: 'QH',
    name: 'Altyn Air Airlines'
  },
  {
    ident: 'QI',
    name: 'Cimber Sterling'
  },
  {
    ident: 'QJ',
    name: 'Air Company Latpass'
  },
  {
    ident: 'QK',
    name: 'Air Canada Jazz'
  },
  {
    ident: 'QL',
    name: 'Aero Lanka Pvt Ltd'
  },
  {
    ident: 'QM',
    name: 'Air Malawi Limited'
  },
  {
    ident: 'QN',
    name: 'Air Armenia'
  },
  {
    ident: 'QO',
    name: 'Quantum Air'
  },
  {
    ident: 'QP',
    name: 'Airkenya Aviation'
  },
  {
    ident: 'QQ',
    name: 'Alliance Airlines'
  },
  {
    ident: 'QR',
    name: 'Qatar Airways'
  },
  {
    ident: 'QS',
    name: 'Smartwings'
  },
  {
    ident: 'QT',
    name: 'Regional Air Pty'
  },
  {
    ident: 'QU',
    name: 'Utair - Ukraine'
  },
  {
    ident: 'QV',
    name: 'Lao Airlines'
  },
  {
    ident: 'QW',
    name: 'Blue Wings'
  },
  {
    ident: 'QX',
    name: 'Horizon Air'
  },
  {
    ident: 'QY',
    name: 'European Air Transport'
  },
  {
    ident: 'QZ',
    name: 'Pt Indonesia Airasia'
  },
  {
    ident: 'R0',
    name: 'Carlson Wagonlit Travel'
  },
  {
    ident: 'R2',
    name: 'Orenair'
  },
  {
    ident: 'R3',
    name: 'Yakutia Airlines'
  },
  {
    ident: 'R4',
    name: 'Russia State Transport Company'
  },
  {
    ident: 'R5',
    name: 'Malta Air Charter'
  },
  {
    ident: 'R6',
    name: 'Dot Lt'
  },
  {
    ident: 'R7',
    name: 'Aserca Airlines C.a.'
  },
  {
    ident: 'R8',
    name: 'Kyrghyzstan Airlines'
  },
  {
    ident: 'RA',
    name: 'Nepal Airlinesrporation'
  },
  {
    ident: 'RB',
    name: 'Syrian Arab Airlines'
  },
  {
    ident: 'RC',
    name: 'Atlantic Airways Faroe Is'
  },
  {
    ident: 'RD',
    name: 'Ryan Intl Airlines'
  },
  {
    ident: 'RE',
    name: 'Aer Arann'
  },
  {
    ident: 'RF',
    name: 'Ord Air Charter'
  },
  {
    ident: 'RG',
    name: 'Varig'
  },
  {
    ident: 'RH',
    name: 'Regionnair'
  },
  {
    ident: 'RI',
    name: 'P.T.Mandala Airlines'
  },
  {
    ident: 'RJ',
    name: 'Royal Jordanian'
  },
  {
    ident: 'RK',
    name: 'Region-Airline'
  },
  {
    ident: 'RL',
    name: 'Royal Falcon Airlines'
  },
  {
    ident: 'RM',
    name: 'Regional Air'
  },
  {
    ident: 'RN',
    name: 'Air Horizons'
  },
  {
    ident: 'RO',
    name: 'Tarom-Romanian Air Transport'
  },
  {
    ident: 'RP',
    name: 'Chautauqua Airlines'
  },
  {
    ident: 'RQ',
    name: 'Kam Air'
  },
  {
    ident: 'RR',
    name: 'Ryanair Sun'
  },
  {
    ident: 'RS',
    name: 'Sky Regional Airlines'
  },
  {
    ident: 'RT',
    name: 'Rak Airways'
  },
  {
    ident: 'RU',
    name: 'Tci Skyking'
  },
  {
    ident: 'RV',
    name: 'Caspian Airlines Servicempany'
  },
  {
    ident: 'RW',
    name: 'Ras Fluggesellschaft'
  },
  {
    ident: 'RX',
    name: 'Regent Airways'
  },
  {
    ident: 'RY',
    name: 'Pacific Royale Airways'
  },
  {
    ident: 'RZ',
    name: 'Sansa'
  },
  {
    ident: 'S.',
    name: 'Star Alliance'
  },
  {
    ident: 'S0',
    name: 'Slok Air Intl Gambia Ltd'
  },
  {
    ident: 'S1',
    name: 'Sosa Airlines'
  },
  {
    ident: 'S2',
    name: 'Jet Lite'
  },
  {
    ident: 'S3',
    name: 'Santa Barbara Airlines C.a.'
  },
  {
    ident: 'S4',
    name: 'Sata Internacional'
  },
  {
    ident: 'S5',
    name: 'Small Planet Airlines'
  },
  {
    ident: 'S6',
    name: 'Salmon Air'
  },
  {
    ident: 'S7',
    name: 'Siberia Airlines'
  },
  {
    ident: 'S8',
    name: 'Charlan Air Charter'
  },
  {
    ident: 'S9',
    name: 'Sky Air World'
  },
  {
    ident: 'SA',
    name: 'South African Airways'
  },
  {
    ident: 'SB',
    name: 'Aircalin'
  },
  {
    ident: 'SC',
    name: 'Shandong Airlines'
  },
  {
    ident: 'SD',
    name: 'Sudan Airways Ltd.'
  },
  {
    ident: 'SE',
    name: 'Xl Airways'
  },
  {
    ident: 'SF',
    name: 'Tassili Airlines'
  },
  {
    ident: 'SG',
    name: 'Spicejet'
  },
  {
    ident: 'SH',
    name: 'Sharp Aviation Pty'
  },
  {
    ident: 'SI',
    name: 'Blue Islands'
  },
  {
    ident: 'SJ',
    name: 'Pt Sriwijaya Air'
  },
  {
    ident: 'SK',
    name: 'Scandinavian Airlines'
  },
  {
    ident: 'SL',
    name: 'Rio-Sul Servicos Aereos Regionais'
  },
  {
    ident: 'SM',
    name: 'Swedline Express'
  },
  {
    ident: 'SN',
    name: 'Brussels Airline'
  },
  {
    ident: 'SO',
    name: 'Salsa D Haiti'
  },
  {
    ident: 'SP',
    name: 'Sata Air Acores'
  },
  {
    ident: 'SQ',
    name: 'Singapore Airlines'
  },
  {
    ident: 'SR',
    name: 'Oag Worldwide'
  },
  {
    ident: 'SS',
    name: 'Corsair'
  },
  {
    ident: 'ST',
    name: 'Germania Fluggesellschaft Mbh'
  },
  {
    ident: 'SU',
    name: 'Aeroflot Russian Airlines'
  },
  {
    ident: 'SV',
    name: 'Saudi Arabian Airlines'
  },
  {
    ident: 'SW',
    name: 'Air Namibia'
  },
  {
    ident: 'SX',
    name: 'Sky Work Ag'
  },
  {
    ident: 'SY',
    name: 'Sununtry Airlines'
  },
  {
    ident: 'SZ',
    name: 'Air Southwest'
  },
  {
    ident: 'T0',
    name: 'Taca Peru'
  },
  {
    ident: 'T1',
    name: 'Trans Air Congo'
  },
  {
    ident: 'T2',
    name: 'Nakina Air Service'
  },
  {
    ident: 'T3',
    name: 'Eastern Airways'
  },
  {
    ident: 'T4',
    name: 'Trip Linhas Aereas'
  },
  {
    ident: 'T5',
    name: 'Turkmenistan Airlines'
  },
  {
    ident: 'T6',
    name: '1time'
  },
  {
    ident: 'T7',
    name: 'Twin Jet'
  },
  {
    ident: 'T8',
    name: 'Sta Trans African Air'
  },
  {
    ident: 'T9',
    name: 'Transmedian Airlines'
  },
  {
    ident: 'TA',
    name: 'Taca International Airlines S.a.'
  },
  {
    ident: 'TB',
    name: 'JetAirFly'
  },
  {
    ident: 'TC',
    name: 'Air Tanzaniampany'
  },
  {
    ident: 'TD',
    name: 'Atlantis European'
  },
  {
    ident: 'TE',
    name: 'Lithuanina Airlines'
  },
  {
    ident: 'TF',
    name: 'Malmo Aviation'
  },
  {
    ident: 'TG',
    name: 'Thai Airways Intl.'
  },
  {
    ident: 'TH',
    name: 'Bannect'
  },
  {
    ident: 'TI',
    name: 'Tailwind Airlines'
  },
  {
    ident: 'TJ',
    name: 'Tradewind Aviation'
  },
  {
    ident: 'TK',
    name: 'Turkish Airlines'
  },
  {
    ident: 'TL',
    name: 'Airnorth Regional'
  },
  {
    ident: 'TM',
    name: 'Lam - Linhas Aereas De Mocambique'
  },
  {
    ident: 'TN',
    name: 'Air Tahiti Nui'
  },
  {
    ident: 'TO',
    name: 'Transavia.com France'
  },
  {
    ident: 'TP',
    name: 'TAP Air Portugal'
  },
  {
    ident: 'TQ',
    name: 'Tandem Aero'
  },
  {
    ident: 'TR',
    name: 'Tiger Airways'
  },
  {
    ident: 'TS',
    name: 'Air Transat'
  },
  {
    ident: 'TT',
    name: 'Tiger Airways Australia'
  },
  {
    ident: 'TU',
    name: 'Tunisair'
  },
  {
    ident: 'TV',
    name: 'Brussels Airlines Fly'
  },
  {
    ident: 'TW',
    name: 'T Way Air'
  },
  {
    ident: 'TX',
    name: 'Air Caraibes'
  },
  {
    ident: 'TY',
    name: 'Air Caledonie'
  },
  {
    ident: 'TZ',
    name: 'Scoot'
  },
  {
    ident: 'U0',
    name: 'Sabre Airline'
  },
  {
    ident: 'U1',
    name: 'Videcom'
  },
  {
    ident: 'U2',
    name: 'Easyjet Airline'
  },
  {
    ident: 'U3',
    name: 'Avies Ltd'
  },
  {
    ident: 'U4',
    name: 'Progress Multitrade Co'
  },
  {
    ident: 'U5',
    name: 'USA 3000 Airlines'
  },
  {
    ident: 'U6',
    name: 'Ural Airlines'
  },
  {
    ident: 'U7',
    name: 'Air Uganda'
  },
  {
    ident: 'U8',
    name: 'Armavia'
  },
  {
    ident: 'U9',
    name: 'Tatarstan State Enterprise Aircompany'
  },
  {
    ident: 'UA',
    name: 'United Airlines'
  },
  {
    ident: 'UB',
    name: 'Myanma Airways'
  },
  {
    ident: 'UC',
    name: 'Lan Cargo'
  },
  {
    ident: 'UD',
    name: 'Hex Air'
  },
  {
    ident: 'UE',
    name: 'Nasair'
  },
  {
    ident: 'UF',
    name: 'Ukrainian Mediterranean Airlines'
  },
  {
    ident: 'UG',
    name: 'SevenAir S.A.'
  },
  {
    ident: 'UH',
    name: 'US Helicopter Corp'
  },
  {
    ident: 'UI',
    name: 'Eurocypria Airline'
  },
  {
    ident: 'UJ',
    name: 'USA Jet Airlines'
  },
  {
    ident: 'UK',
    name: 'Vistara'
  },
  {
    ident: 'UL',
    name: 'Srilankan Airlines'
  },
  {
    ident: 'UM',
    name: 'Air Zimbabwe (pvt)'
  },
  {
    ident: 'UN',
    name: 'Transaero Airlines'
  },
  {
    ident: 'UO',
    name: 'Hong Kong Express Airways'
  },
  {
    ident: 'UP',
    name: 'Bahamasair Holdings'
  },
  {
    ident: 'UQ',
    name: 'Sky Jet Aviation Ltd'
  },
  {
    ident: 'UR',
    name: 'Utair-Express'
  },
  {
    ident: 'US',
    name: 'US Airways'
  },
  {
    ident: 'UT',
    name: 'Utair Aviation'
  },
  {
    ident: 'UU',
    name: 'Air Austral'
  },
  {
    ident: 'UV',
    name: 'Helicopteros Del Sureste S.a.'
  },
  {
    ident: 'UW',
    name: 'Universal Airlines'
  },
  {
    ident: 'UX',
    name: 'Air Europa Lineas Aereas S.a.'
  },
  {
    ident: 'UY',
    name: 'Cameroon Airlines'
  },
  {
    ident: 'UZ',
    name: 'Buraq Air Transport'
  },
  {
    ident: 'V0',
    name: 'Conviasa'
  },
  {
    ident: 'V2',
    name: 'Vision Airlines'
  },
  {
    ident: 'V3',
    name: 'Carpatair S.a.'
  },
  {
    ident: 'V4',
    name: 'Vieques Air Link Inc'
  },
  {
    ident: 'V5',
    name: 'Royal Aruban Airlines'
  },
  {
    ident: 'V6',
    name: 'Air Atlantique'
  },
  {
    ident: 'V7',
    name: 'Volotea'
  },
  {
    ident: 'V8',
    name: 'Iliamna Air Taxi Inc'
  },
  {
    ident: 'V9',
    name: 'Star1 Airlines'
  },
  {
    ident: 'VA',
    name: 'Virgin Australia Intl.'
  },
  {
    ident: 'VB',
    name: 'Vivaaerobus'
  },
  {
    ident: 'VC',
    name: 'Air Australia'
  },
  {
    ident: 'VD',
    name: 'Swedjet Airways Ab'
  },
  {
    ident: 'VE',
    name: 'Cai Second Spa'
  },
  {
    ident: 'VF',
    name: 'Valuair'
  },
  {
    ident: 'VG',
    name: 'Vlm Trading As Cityjet'
  },
  {
    ident: 'VH',
    name: 'Aeropostal Alas De Venezuela C.a.'
  },
  {
    ident: 'VJ',
    name: 'Vietjet Air'
  },
  {
    ident: 'VK',
    name: 'Virgin Nigeria Airways'
  },
  {
    ident: 'VL',
    name: 'Sonicblue Airways'
  },
  {
    ident: 'VM',
    name: 'Macair Jet'
  },
  {
    ident: 'VN',
    name: 'Vietnam Airlines'
  },
  {
    ident: 'VO',
    name: 'Tyrolean Airways'
  },
  {
    ident: 'VP',
    name: 'Vasp'
  },
  {
    ident: 'VQ',
    name: 'Viking Hellas Airlines'
  },
  {
    ident: 'VR',
    name: 'Tranportes Aereos De Cabo Verde'
  },
  {
    ident: 'VS',
    name: 'Virgin Atlantic Airways Limited'
  },
  {
    ident: 'VT',
    name: 'Air Tahiti'
  },
  {
    ident: 'VU',
    name: 'Air Ivoire'
  },
  {
    ident: 'VV',
    name: 'Aerosvit Airlines'
  },
  {
    ident: 'VW',
    name: 'Transportes Aeromar S A  de C V'
  },
  {
    ident: 'VX',
    name: 'Virgin America'
  },
  {
    ident: 'VY',
    name: 'Vueling Airlines S.a.'
  },
  {
    ident: 'VZ',
    name: 'Mytravel Airways Ltd'
  },
  {
    ident: 'W1',
    name: 'Non Hosted A.r.e.'
  },
  {
    ident: 'W2',
    name: 'Flexflight'
  },
  {
    ident: 'W3',
    name: 'Arik Air'
  },
  {
    ident: 'W4',
    name: 'Wizzair Air Malta'
  },
  {
    ident: 'W5',
    name: 'Mahan Airlines'
  },
  {
    ident: 'W6',
    name: 'Wizz Air Hungary'
  },
  {
    ident: 'W7',
    name: 'Sayakhat Airlines'
  },
  {
    ident: 'W8',
    name: 'La Costena'
  },
  {
    ident: 'W9',
    name: 'Wizz Air UK'
  },
  {
    ident: 'WA',
    name: 'KLM Cityhopper B.V'
  },
  {
    ident: 'WB',
    name: 'Rwandair Express'
  },
  {
    ident: 'WC',
    name: 'Islena Airlines'
  },
  {
    ident: 'WD',
    name: 'Dairo Air Service'
  },
  {
    ident: 'WE',
    name: 'Rheintalflug Seewald'
  },
  {
    ident: 'WF',
    name: 'Wideroes Flyveselskap A'
  },
  {
    ident: 'WG',
    name: 'Sunwing Airlines'
  },
  {
    ident: 'WH',
    name: 'Webjet Linhas Aereas S'
  },
  {
    ident: 'WI',
    name: 'Tradewind Caribbean Airlines'
  },
  {
    ident: 'WJ',
    name: 'Labrador Airways'
  },
  {
    ident: 'WK',
    name: 'Edelweiss Air AG'
  },
  {
    ident: 'WL',
    name: 'Aeroperlas'
  },
  {
    ident: 'WM',
    name: 'Windward Island Airways'
  },
  {
    ident: 'WN',
    name: 'Southwest Airlines (texas)'
  },
  {
    ident: 'WO',
    name: 'World Airways'
  },
  {
    ident: 'WP',
    name: 'Island Air'
  },
  {
    ident: 'WQ',
    name: 'Romanian Aviation'
  },
  {
    ident: 'WR',
    name: 'Royal Tongan Airlines'
  },
  {
    ident: 'WS',
    name: 'Westjet'
  },
  {
    ident: 'WT',
    name: 'Wasaya Airways'
  },
  {
    ident: 'WU',
    name: 'Wizz Air Ukraine'
  },
  {
    ident: 'WV',
    name: 'Wings Air'
  },
  {
    ident: 'WW',
    name: 'Wow Air'
  },
  {
    ident: 'WX',
    name: 'Cityjet'
  },
  {
    ident: 'WY',
    name: 'Oman Air'
  },
  {
    ident: 'WZ',
    name: 'West African Airlines'
  },
  {
    ident: 'X2',
    name: 'Airlines Reporting Crp'
  },
  {
    ident: 'X3',
    name: 'TUIFly GmbH'
  },
  {
    ident: 'X4',
    name: 'Air Excursions, Llc'
  },
  {
    ident: 'X5',
    name: 'Afrique Airlines S.a.'
  },
  {
    ident: 'X6',
    name: 'Airlines Reporting Crp'
  },
  {
    ident: 'X7',
    name: 'Exec Air'
  },
  {
    ident: 'X8',
    name: 'Avion Express Malta Ltd for Eurowings'
  },
  {
    ident: 'X9',
    name: 'City Star Airlines'
  },
  {
    ident: 'XA',
    name: 'Arinc'
  },
  {
    ident: 'XB',
    name: 'IATA'
  },
  {
    ident: 'XC',
    name: 'Corendon Airlines'
  },
  {
    ident: 'XD',
    name: 'Airlines Reporting Crp'
  },
  {
    ident: 'XE',
    name: 'Expressjet Airlines'
  },
  {
    ident: 'XF',
    name: 'Vladivostok Air'
  },
  {
    ident: 'XG',
    name: 'Sunexpress'
  },
  {
    ident: 'XH',
    name: 'Special Services'
  },
  {
    ident: 'XI',
    name: 'Aerotel'
  },
  {
    ident: 'XJ',
    name: 'Mesaba Aviation'
  },
  {
    ident: 'XK',
    name: 'Ccm Airlines'
  },
  {
    ident: 'XL',
    name: 'LAN Ecuador Aeroplane S.A.'
  },
  {
    ident: 'XM',
    name: 'Cai First Spa'
  },
  {
    ident: 'XN',
    name: 'Xpressair'
  },
  {
    ident: 'XO',
    name: 'China Xinjiang Airlines'
  },
  {
    ident: 'XP',
    name: 'Xtra Airways'
  },
  {
    ident: 'XQ',
    name: 'Sunexpress'
  },
  {
    ident: 'XR',
    name: 'Skywest Airlines Pty'
  },
  {
    ident: 'XS',
    name: 'Sita'
  },
  {
    ident: 'XT',
    name: 'Air Exel Netherlands'
  },
  {
    ident: 'XU',
    name: 'African Express Airways'
  },
  {
    ident: 'XV',
    name: 'BVI Airways'
  },
  {
    ident: 'XW',
    name: 'Sky Express'
  },
  {
    ident: 'XX',
    name: 'Ground Service'
  },
  {
    ident: 'XY',
    name: 'Nas Air - Natl Air Svc'
  },
  {
    ident: 'XZ',
    name: 'South African Express Airways'
  },
  {
    ident: 'Y0',
    name: 'Yellow Airtaxi'
  },
  {
    ident: 'Y3',
    name: 'Skyway Yx Connection'
  },
  {
    ident: 'Y4',
    name: 'Volaris'
  },
  {
    ident: 'Y5',
    name: 'Golden Myanmar Airlines'
  },
  {
    ident: 'Y6',
    name: 'Metro Batavia Air'
  },
  {
    ident: 'Y7',
    name: 'Airline Tajmyr'
  },
  {
    ident: 'Y8',
    name: 'Yangtze River Express'
  },
  {
    ident: 'Y9',
    name: 'Kish Airlines'
  },
  {
    ident: 'YB',
    name: 'South African Express'
  },
  {
    ident: 'YC',
    name: 'Yamal Airlines'
  },
  {
    ident: 'YD',
    name: 'Mauritania Airways'
  },
  {
    ident: 'YE',
    name: 'Yanda Airlines'
  },
  {
    ident: 'YF',
    name: 'Dept Of National Defence'
  },
  {
    ident: 'YG',
    name: 'South Airlines'
  },
  {
    ident: 'YH',
    name: 'Yangon Airways'
  },
  {
    ident: 'YI',
    name: 'Air Sunshine'
  },
  {
    ident: 'YJ',
    name: 'Nac Aviation Business'
  },
  {
    ident: 'YK',
    name: 'Avia Trafficmpany'
  },
  {
    ident: 'YM',
    name: 'Montenegro Airlines'
  },
  {
    ident: 'YN',
    name: 'Air Creebec Inc'
  },
  {
    ident: 'YO',
    name: 'Heli Air Monaco'
  },
  {
    ident: 'YP',
    name: 'Aero Lloyd Flugreisen'
  },
  {
    ident: 'YQ',
    name: 'Aircompany Polet'
  },
  {
    ident: 'YR',
    name: 'Scenic Airlines'
  },
  {
    ident: 'YS',
    name: 'Regionalmpagnie Aerienne Europeene'
  },
  {
    ident: 'YT',
    name: 'Yeti Airlines'
  },
  {
    ident: 'YU',
    name: 'Euroatlantic Airways'
  },
  {
    ident: 'YV',
    name: 'Mesa Airlines'
  },
  {
    ident: 'YW',
    name: 'Air Nostrum L.a.m.s.a.'
  },
  {
    ident: 'YX',
    name: 'Republic Airline'
  },
  {
    ident: 'YY',
    name: 'TS Airlines'
  },
  {
    ident: 'YZ',
    name: 'Holland Exel'
  },
  {
    ident: 'Z0',
    name: 'Various'
  },
  {
    ident: 'Z1',
    name: 'Selected Charter'
  },
  {
    ident: 'Z2',
    name: 'Zest Airways'
  },
  {
    ident: 'Z3',
    name: 'Promech'
  },
  {
    ident: 'Z4',
    name: 'Zoom Airlines Inc'
  },
  {
    ident: 'Z5',
    name: 'Gmg Airlines'
  },
  {
    ident: 'Z6',
    name: 'Dnieproavia Joint Stock Aviation'
  },
  {
    ident: 'Z7',
    name: 'Adc Airlines'
  },
  {
    ident: 'Z8',
    name: 'Amaszonas Sa'
  },
  {
    ident: 'Z9',
    name: 'Bek Air'
  },
  {
    ident: 'ZA',
    name: 'Astair Limited Company'
  },
  {
    ident: 'ZB',
    name: 'Monarch Airlines'
  },
  {
    ident: 'ZC',
    name: 'Korongo Airlines'
  },
  {
    ident: 'ZD',
    name: 'Dolphin Air'
  },
  {
    ident: 'ZE',
    name: 'Eastar Jet'
  },
  {
    ident: 'ZF',
    name: 'Athens Airways'
  },
  {
    ident: 'ZG',
    name: 'Viva Macau Limited'
  },
  {
    ident: 'ZH',
    name: 'Shenzhen Airlines'
  },
  {
    ident: 'ZI',
    name: 'Aigle Azur'
  },
  {
    ident: 'ZJ',
    name: 'Zambezi Airlines'
  },
  {
    ident: 'ZK',
    name: 'Great Lakes Aviation'
  },
  {
    ident: 'ZL',
    name: 'Regional Express'
  },
  {
    ident: 'ZM',
    name: 'Alliance Express Rwanda'
  },
  {
    ident: 'ZN',
    name: 'Naysa'
  },
  {
    ident: 'ZO',
    name: 'Central Air Transport Services'
  },
  {
    ident: 'ZP',
    name: 'Silk Way Airlines'
  },
  {
    ident: 'ZQ',
    name: 'Ansett New Zealand'
  },
  {
    ident: 'ZR',
    name: 'Muk Air'
  },
  {
    ident: 'ZS',
    name: 'Sama'
  },
  {
    ident: 'ZT',
    name: 'Titan Airways Limited'
  },
  {
    ident: 'ZU',
    name: 'Air Bashkortostan'
  },
  {
    ident: 'ZV',
    name: 'Air Midwest Inc'
  },
  {
    ident: 'ZW',
    name: 'Air Wisconsin'
  },
  {
    ident: 'ZX',
    name: 'Air Alliance'
  },
  {
    ident: 'ZY',
    name: 'Sky Airlines'
  },
  {
    ident: 'ZZ',
    name: 'Aircraft In Flight'
  },
  {
    ident: 'metronom',
    name: 'metronom'
  },
  {
    ident: 'alex - Die Länderbahn GmbH DLB',
    name: 'alex - Die Länderbahn GmbH DLB'
  },
  {
    ident: 'VIAS GmbH',
    name: 'VIAS GmbH'
  },
  {
    ident: 'Hessische Landesbahn',
    name: 'Hessische Landesbahn'
  },
  {
    ident: 'DEUTSCHE BAHN',
    name: 'DEUTSCHE BAHN'
  },
  {
    ident: 'NordWestBahn',
    name: 'NordWestBahn'
  },
  {
    ident: 'Bayerische Oberlandbahn',
    name: 'Bayerische Oberlandbahn'
  },
  {
    ident: 'ÖBB',
    name: 'ÖBB'
  },
  {
    ident: 'OTHER',
    name: 'OTHER'
  },
  {
    ident: 'Meridian',
    name: 'Meridian'
  },
  {
    ident: 'OE',
    name: 'OE'
  },
  {
    ident: 'N4',
    name: 'N4'
  },
  {
    ident: 'REGIOBAHN',
    name: 'REGIOBAHN'
  },
  {
    ident: 'National Express',
    name: 'National Express'
  },
  {
    ident: 'B0',
    name: 'B0'
  },
  {
    ident: 'eurobahn',
    name: 'eurobahn'
  },
  {
    ident: 'WestfalenBahn',
    name: 'WestfalenBahn'
  },
  {
    ident: 'enno',
    name: 'enno'
  },
  {
    ident: 'Bayerische Regiobahn',
    name: 'Bayerische Regiobahn'
  },
  {
    ident: 'agilis-Schnellzug',
    name: 'agilis-Schnellzug'
  },
  {
    ident: 'Ostdeutsche Eisenbahn GmbH',
    name: 'Ostdeutsche Eisenbahn GmbH'
  },
  {
    ident: 'MittelrheinBahn (TransRegio)',
    name: 'MittelrheinBahn (TransRegio)'
  },
  {
    ident: 'Württembergische Eisenbahn-Gesellschaft mbH',
    name: 'Württembergische Eisenbahn-Gesellschaft mbH'
  },
  {
    ident: 'ABELLIO Rail Mitteldeutschland GmbH',
    name: 'ABELLIO Rail Mitteldeutschland GmbH'
  },
  {
    ident: 'erixx',
    name: 'erixx'
  },
  {
    ident: 'VIAS Rail GmbH',
    name: 'VIAS Rail GmbH'
  },
  {
    ident: 'Erfurter Bahn',
    name: 'Erfurter Bahn'
  },
  {
    ident: '4X2',
    name: 'Avion Express Malta'
  }
];
