import * as React from 'react';
import { connect } from 'react-redux';
import {
  Redirect,
  Route,
  RouteComponentProps,
  Router,
  Switch,
} from 'react-router-dom';
const urlParse = require('url-parse');

// Utils
import {
  cond,
  isNil,
  verifyProperty,
} from '@src/shared/src/util/general';
import {
  baseErrorCond,
  hashHistory,
  redirectToBackendForLogin,
} from '@toolkit/util/app';
import i18n from '@toolkit/util/i18n';
import LocaleResolver from '@src/shared/src/util/resolvers/LocaleResolver';
// Constants
import { ROUTES } from '@toolkit/const/app';
// Actions
import {
  searchActions,
  adminUserActions,
} from '@src/shared/src/actions';
// Models
import {
  AppSettingsModel,
  UserModel,
  PassengerModel,
} from '@src/shared/src/models';
// Interfaces
import { ConnectedRedux, IRootState } from '@src/store';
// Components
import { AppRouter } from '@src/routes';
import { LoaderOverlay } from '@toolkit/ui';
import { RedirectToBackendSwitch } from './RedirectToBackendSwitch';
import { API_URL } from '@src/shared/src/const/api';
// Styles

type Props = ConnectedRedux<IRootState> & RouteComponentProps & {
  userId:number;
  config:AppSettingsModel;
  searchId:number;
  searchCreatorId:number;
  searchPassengers:PassengerModel[];
};

type State = {
  isNotLoggedIn: boolean;
};

class SessionRouter extends React.PureComponent<Props, State> {

  readonly state:State = {
    isNotLoggedIn: false,
  };

  private historyUnlisten:() => void = () => null;

  componentDidMount() {
    this.checkUserAuth(this.props.userId);
    this.historyUnlisten = hashHistory.listen(() => {
      window.scrollTo(0, 0);
      this.verifySearch();
    });
  }

  componentWillUnmount() {
    this.historyUnlisten();
  }

  private checkUserAuth = (userId:number) => {
    if (isNil(userId)) {
      this.props.dispatch(adminUserActions.verifyUserAsync.request({
        onSuccess: (user:UserModel) => {
          // Change user language/locale according to user preference
          i18n.changeLanguage(user.preference.effectiveLocale);
          // As LocaleResolver handles only numbers, date and currency which are independent from the translation changes,
          // we shall keep using 'preference.language', as it holds the original language used in formatters, instead of using
          // 'preference.effectiveLocale' then try to extract the original language from it. For example:
          // in custom locale case, preference would hold: {language: 'de-DE', effective_locale: 'de_de_all4one'}
          // in original locale case, preference would hold: {language: 'de-DE', effective_locale: 'de-DE'}
          LocaleResolver.setupInstance({
            locale: user.preference.language,
            currency: 'EUR',
          });
          this.verifySearch();
        },
        onError: redirectToBackendForLogin,
      }));
    } else {
      this.verifySearch();
    }
  }


  private getSearchId = () => {
    const locAddress = hashHistory.location.pathname;
    const searchId = Number(locAddress.substring(locAddress.lastIndexOf(ROUTES.HOME) + 1));
    if (!isNaN(searchId) && searchId > 0) {
      return searchId;
    } else {
      return null;
    }
  }

  private verifySearch = () => {
    const searchId = this.getSearchId();
    if (searchId) {
      if (this.props.searchCreatorId === -1 && this.props.searchId !== searchId) {
        this.props.dispatch(searchActions.setId(searchId));
        this.props.dispatch(searchActions.initSearch({
          onError: cond(baseErrorCond),
          searchId,
        }));
      }
    }
  }

  renderBookingDetails = (renderProps: any): any => {
    const bookingId = verifyProperty(null, renderProps, ['match', 'params', 'booking']);
    if (bookingId) {
      window.location.assign(`${API_URL.DEFAULT}/org_admin/travel/bookings/${bookingId}`)
    } else {
      window.location.assign(`${API_URL.DEFAULT}/org_admin/travel/bookings`)
    }
    return null;
  }

  render() {
    if (this.props.searchCreatorId === -1 && this.props.userId) {
      return (
        <div>
          <LoaderOverlay title="Loading state..." showSpinner={true}/>
        </div>
      );
    }

    /* We set the value (true) to a global object only if we receive as a param in the URL, for BE to use when calling
      derp for webview */
    const urlParams = urlParse(this.props.location.search, true).query;
    const webviewParam = verifyProperty(false, urlParams, ['webview']) === 'true';
    const redirectUrlParam = verifyProperty(undefined, urlParams, ['redirect_url']);

    if (webviewParam) {
      (window as any).__IS_WEBVIEW = webviewParam;
    }

    if (this.state.isNotLoggedIn) {
      return (
        <Redirect to={{
          pathname: ROUTES.AUTH.LOGIN,
          state: {
            internalPath: this.props.location.pathname,
            orgAdminPath: redirectUrlParam,
          }
        }} />
      );
    }

    return (
      <Router history={hashHistory}>
        <Switch>
          <Route exact path={`${this.props.match.url}admin/user/user_booking_detail/:booking`} render={this.renderBookingDetails}/>
          <Route exact path={`${this.props.match.url}admin/company/user_booking_detail/:booking`} render={this.renderBookingDetails}/>
          <Route path={`${this.props.match.url}admin`} component={RedirectToBackendSwitch}/>
          <Route path={ROUTES.HOME} component={AppRouter}/>
        </Switch>
      </Router>
    );
  }
}
const mapState = (state:IRootState) => ({
  searchId: state.search.currentSearch.id,
  searchCreatorId: state.search.currentSearch.creatorId,
  searchPassengers: state.search.currentSearch.passengers,
  userId: state.adminUser.userId,
});
export default connect(mapState)(SessionRouter);
